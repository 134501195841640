import { Observable } from 'rxjs';

import { ActionItem } from '../models/action-item';
import { BaseDAO } from './base.dao';
import { ImperativeDAO } from './imperative.dao';
import { IssueDAO } from './issue.dao';
import { UserDAO } from './user.dao';

export class ActionItemDAO extends BaseDAO<ActionItem> {
	public get owner(): Observable<UserDAO> {
		return this.getSubDocumentDao((x) => x.owner, UserDAO);
	}

	public get issue(): Observable<IssueDAO> {
		return this.getSubDocumentDao((x) => x.issue, IssueDAO);
	}

	public get imperative(): Observable<ImperativeDAO> {
		return this.getSubDocumentDao((x) => x.imperative, ImperativeDAO);
	}
}
