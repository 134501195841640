import { Observable } from 'rxjs';

import { Strategy } from '../models';
import { FsCollection } from '../utils';
import { AssetTypeDAO } from './asset-type.dao';
import { BaseDAO } from './base.dao';
import { BookingsModelDAO } from './bookings-model.dao';
import { ChannelDAO } from './channel.dao';
import { CustomScorecardDAO } from './custom-scorecard.dao';
import { InitiativeDAO } from './initiative.dao';
import { ProcessDAO } from './process.dao';
import { QuarterlyPlanDAO } from './quarterly-plan.dao';
import { StreamDataDAO } from './stream-data.dao';
import { TacticDAO } from './tactic.dao';

export class StrategyDAO extends BaseDAO<Strategy> {
	private _bookingsModels: Observable<BookingsModelDAO[]>;
	private _streamData: Observable<StreamDataDAO[]>;
	private _processes: Observable<ProcessDAO[]>;
	private _initiatives: Observable<InitiativeDAO[]>;
	private _quarterlyPlans: Observable<QuarterlyPlanDAO[]>;
	private _tactics: Observable<TacticDAO[]>;
	private _channels: Observable<ChannelDAO[]>;
	private _assetTypes: Observable<AssetTypeDAO[]>;
	private _customScorecards: Observable<CustomScorecardDAO[]>;

	public get bookingsModels(): Observable<BookingsModelDAO[]> {
		if (this._bookingsModels == null) {
			const path = `${this.path}/${FsCollection.BookingsModels}`;
			this._bookingsModels = this.initCollection(BookingsModelDAO, path);
		}
		return this._bookingsModels;
	}

	public get streamData(): Observable<StreamDataDAO[]> {
		if (this._streamData == null) {
			const path = `${this.path}/${FsCollection.StreamData}`;
			this._streamData = this.initCollection(StreamDataDAO, path);
		}
		return this._streamData;
	}

	public get tactics(): Observable<TacticDAO[]> {
		if (this._tactics == null) {
			const path = `${this.path}/tactics`;
			this._tactics = this.initCollection(TacticDAO, path);
		}
		return this._tactics;
	}

	public get channels(): Observable<ChannelDAO[]> {
		if (this._channels == null) {
			const path = `${this.path}/channels`;
			this._channels = this.initCollection(ChannelDAO, path);
		}
		return this._channels;
	}

	public get assetTypes(): Observable<AssetTypeDAO[]> {
		if (this._assetTypes == null) {
			const path = `${this.path}/assetTypes`;
			this._assetTypes = this.initCollection(AssetTypeDAO, path);
		}
		return this._assetTypes;
	}

	public get processes(): Observable<ProcessDAO[]> {
		if (this._processes == null) {
			const path = `${this.path}/processes`;
			this._processes = this.initCollection(ProcessDAO, path);
		}
		return this._processes;
	}

	public get initiatives(): Observable<InitiativeDAO[]> {
		if (this._initiatives == null) {
			const path = `${this.path}/initiatives`;
			this._initiatives = this.initCollection(InitiativeDAO, path);
		}
		return this._initiatives;
	}

	public get quarterlyPlans(): Observable<QuarterlyPlanDAO[]> {
		if (this._quarterlyPlans == null) {
			const path = `${this.path}/quarterlyPlans`;
			this._quarterlyPlans = this.initCollection(QuarterlyPlanDAO, path);
		}
		return this._quarterlyPlans;
	}

	public get customScorecards(): Observable<CustomScorecardDAO[]> {
		if (this._customScorecards == null) {
			const path = `${this.path}/customScorecards`;
			this._customScorecards = this.initCollection(CustomScorecardDAO, path);
		}
		return this._customScorecards;
	}
}
