import {
	DocumentChangeAction, DocumentData, QueryDocumentSnapshot, QuerySnapshot,
} from '@angular/fire/compat/firestore';

import { Identity } from '../models/identity';

// Sometimes items can move from the results of one query to another.
// In this interim its source may emit it missing before its destination emits
// it inclusion. So we need to debounce a little to avoid the item momentarily disappearing.
export const COMBINED_QUERY_DEBOUNCE = 250;

export class BaseRepository<T extends Identity> {
	protected mapSingleGet(snapshot: QueryDocumentSnapshot<DocumentData>): T {
		if (!snapshot.exists) return null;
		return { id: snapshot.id, path: snapshot.ref.path, ...snapshot.data() } as T;
	}

	protected mapListGet(snapshot: QuerySnapshot<DocumentData>): T[] {
		if (snapshot.empty) return [];
		return snapshot.docs.map(this.mapSingleGet);
	}

	protected mapFirstGet(snapshot: QuerySnapshot<DocumentData>): T {
		if (snapshot.empty) return null;
		const doc = snapshot.docs[0];
		return this.mapSingleGet(doc);
	}

	protected mapSingleSnapshot(action: DocumentChangeAction<T>): T {
		return this.mapSingleGet(action.payload.doc);
	}

	protected mapListSnapshot(actions: DocumentChangeAction<T>[]): T[] {
		return actions.map((action) => this.mapSingleSnapshot(action));
	}

	protected mapFirstSnapshot(actions: DocumentChangeAction<T>[]): T {
		if (!actions.length) return null;
		return this.mapSingleSnapshot(actions[0]);
	}

	protected mapUnique(entities: T[][]) {
		const flatEntities = entities.reduce((p, c) => p.concat(c), []);
		const uniqueEntities = flatEntities.reduce((p, c) => {
			if (!p.has(c.id)) {
				p.set(c.id, c);
			}
			return p;
		}, new Map<string, T>());
		return Array.from(uniqueEntities.values());
	}
}
