export enum HeaderBanner {
	ProcessDefinition,
	Foundation,
	GtmProgress,
	TargetMarkets,
	DefineMarkets,
	Offerings,
	DefineOfferings,
	RevenueStreams,
	BookingsTargets,
	Initiatives,
	Scorecards,
	ManageTeaser,
	OptimizeTeaser,
}
