import { Component, Input } from '@angular/core';

@Component({
	selector: 'ayeq-kpi-progress-bar',
	templateUrl: './kpi-progress-bar.component.html',
	styleUrls: ['./kpi-progress-bar.component.scss'],
})
export class KpiProgressBarComponent {
	@Input() color: string;
	@Input() value: number;

	get normalizedValue() {
		return this.value > 100 ? 100 : this.value;
	}
}
