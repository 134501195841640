import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input } from '@angular/core';

@Directive({
	selector: '[ayeqTrimLeadingZeros]',
})
export class TrimLeadingZerosDirective {
	@Input() format = '1.0-0';
	constructor(@Inject(ElementRef) private element: ElementRef) {}

	@HostListener('blur') onBlur() {
		const firstChar = this.element.nativeElement.value[0];
		const lastChar = this.element.nativeElement.value[this.element.nativeElement.value.length - 1];
		const prefix = firstChar && Number.isNaN(firstChar) ? firstChar : '';
		const suffix = lastChar && Number.isNaN(lastChar) ? lastChar : '';
		let value = this.element.nativeElement.value.replace(/\D/g, '');
		value = value.replace(/^(?!0$)0+/, '');
		value = DecimalPipe.prototype.transform(value, this.format, 'EN-US');
		if (!value) {
			return;
		}
		this.element.nativeElement.value = prefix + value + suffix;
	}
}
