import { DocumentReference } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';

import { TrackingStatus } from '../enums';
import { Asset } from './asset';
import { AssetType } from './asset-type';
import { Channel } from './channel';
import { Tactic } from './tactic';

export const ACTION_DATE_FORMAT = 'M/d/y';
const UNSPECIFIED_CAMPAIGN_NAME = 'Unspecified';

export interface Campaign {
	createdAt: firebase.firestore.Timestamp;
	asset: DocumentReference;
	assetType: DocumentReference<AssetType>;
	isCrmCampaign: boolean;
	nameOverride?: string;
	crmName: string;
	crmResponseTypes: string[];
	budget: number;
	channels: CampaignChannels;
}

export interface CampaignChannels {
	[key: string]: CampaignChannel;
}

export interface CampaignChannel {
	channel: DocumentReference<Channel>;
	tactics: CampaignTactics;
}

export interface CampaignTactics {
	[key: string]: CampaignTactic;
}

export interface CampaignTactic {
	tactic: DocumentReference<Tactic>;
	pardot: string;
	utmUrl: string;
	formName: string;
	formHandlerName: string;
	landingPageName: string;
	actions: { [key: string]: CampaignTacticAction };
}

export interface CampaignTacticAction {
	date: string; // this is the startDate for a range, but we don't want to migrate data
	endDate?: string;
	status: TrackingStatus;
	assignee: string;
	notes: string;
}

export function determineCampaignName(
	campaign: Campaign,
	assetTypesMap: Map<string, AssetType>,
	assetsMap: Map<string, Asset>
) {
	const assetType = campaign.assetType != null ? assetTypesMap.get(campaign.assetType.id) : null;
	const asset = campaign.asset != null ? assetsMap.get(campaign.asset.id) : null;
	if (assetType != null && assetType.name) {
		if (asset != null && asset.name) {
			return `${assetType.name} - ${asset.name}`;
		} else {
			return assetType.name;
		}
	}
	return UNSPECIFIED_CAMPAIGN_NAME;
}
