export enum Month {
	Jan = 1,
	Feb = 2,
	Mar = 3,
	Apr = 4,
	May = 5,
	Jun = 6,
	Jul = 7,
	Aug = 8,
	Sep = 9,
	Oct = 10,
	Nov = 11,
	Dec = 12
}

export const Months = [
	{
		shortName: 'Jan',
		longName: 'January',
		value: Month.Jan
	},
	{
		shortName: 'Feb',
		longName: 'February',
		value: Month.Feb
	},
	{
		shortName: 'Mar',
		longName: 'March',
		value: Month.Mar
	},
	{
		shortName: 'Apr',
		longName: 'April',
		value: Month.Apr
	},
	{
		shortName: 'May',
		longName: 'May',
		value: Month.May
	},
	{
		shortName: 'Jun',
		longName: 'June',
		value: Month.Jun
	},
	{
		shortName: 'Jul',
		longName: 'July',
		value: Month.Jul
	},
	{
		shortName: 'Aug',
		longName: 'August',
		value: Month.Aug
	},
	{
		shortName: 'Sep',
		longName: 'September',
		value: Month.Sep
	},
	{
		shortName: 'Oct',
		longName: 'October',
		value: Month.Oct
	},
	{
		shortName: 'Nov',
		longName: 'November',
		value: Month.Nov
	},
	{
		shortName: 'Dec',
		longName: 'December',
		value: Month.Dec
	}
];
