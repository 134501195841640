import { Injectable } from '@angular/core';
import { QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import firebase from 'firebase/compat/app';
import { firstValueFrom } from 'rxjs';

import { Entity } from '@app/features/strategy/services';
import { RefreshType, Tenant } from '@app/shared/data';
import { CrmProvider } from '@app/shared/data/enums/crm-provider.enum';
import { MaProvider } from '@app/shared/data/enums/ma-provider.enum';
import { LocalStorageKeys } from '@app/shared/shared/constants';

import { LogService } from './logging';
import { WebStorage } from './webStorage';

const CRM_FUNCTIONS: { [key in CrmProvider]: string } = {
	SALESFORCE: 'getSalesforceEntities',
	HUBSPOT: 'getHubspotEntities',
};

const MA_FUNCTIONS: { [key in MaProvider]: string } = {
	PARDOT: 'getPardotEntities',
	HUBSPOT: 'getHubspotEntities',
};

type FunctionsResponse<T> = T | FunctionsHttpErrorModel;

interface ErrorCode {
	canonicalCode: number;
	status: number;
}

interface FunctionsHttpErrorModel {
	code: firebase.functions.FunctionsErrorCode;
	details?: any;
	httpErrorCode: ErrorCode;
}

class FunctionsHttpError extends Error implements FunctionsHttpErrorModel {
	code: firebase.functions.FunctionsErrorCode;
	details?: any;
	httpErrorCode: ErrorCode;

	constructor(httpError: FunctionsHttpErrorModel) {
		super(httpError.details?.message);
		this.code = httpError.code;
		this.details = httpError.details;
		this.httpErrorCode = httpError.httpErrorCode;
	}
}

@Injectable({ providedIn: 'root' })
export class FunctionsService {
	constructor(
		private aff: AngularFireFunctions,
		private log: LogService
	) {}

	async callFunction<Ti, To>(name: string, data: Ti, options?: firebase.functions.HttpsCallableOptions): Promise<To> {
		const callable = this.aff.httpsCallable<Ti, FunctionsResponse<To>>(name, options);
		let response: FunctionsResponse<To>;
		try {
			response = await firstValueFrom(callable(data));
			if (isError(response)) {
				throw new FunctionsHttpError(response);
			}
		} catch (err) {
			this.log.error(`Failed to execute ${name}`, {}, err);
			throw err;
		}
		return response;
	}

	async getQlikJwt(): Promise<string> {
		try {
			return await this.callFunction('getQlikJwt', {});
		} catch (e) {
			return '';
		}
	}

	refreshQlikData(hasPipelineInRefresh: boolean, type: RefreshType): Promise<void> {
		const tenantId = WebStorage.getItem(LocalStorageKeys.TenantId);
		return this.callFunction(
			hasPipelineInRefresh ? 'triggerPipeline' : 'triggerQlikRefresh',
			{ tenantId, type },
			{ timeout: 5 * 60 * 1000 }
		);
	}

	async getMaEntities(tenantSS: QueryDocumentSnapshot<Tenant>, entity: string, parentId?: string): Promise<Entity[]> {
		const provider = tenantSS.data().maProvider;
		if (!MA_FUNCTIONS[provider]) {
			this.log.error(`getMaEntities not implemented for ${provider ?? 'the tenant'}`);
			return [];
		}
		return this.callFunction(MA_FUNCTIONS[provider], { tenantId: tenantSS.id, entity, parentId });
	}

	async getCrmEntities(tenantSS: QueryDocumentSnapshot<Tenant>, entity: string, parentId?: string): Promise<Entity[]> {
		const provider = tenantSS.data().crmProvider;
		if (!CRM_FUNCTIONS[provider]) {
			this.log.error(`getCrmEntities not implemented for ${provider ?? 'the tenant'}`);
			return [];
		}
		return this.callFunction(CRM_FUNCTIONS[provider], { tenantId: tenantSS.id, entity, parentId });
	}

	async sendWelcomeMessage(uid: string): Promise<void> {
		await this.callFunction('sendWelcomeMessage', { uid });
	}

	async getUserIdByEmail(email: string): Promise<string> {
		return this.callFunction('getUserIdByEmail', { email });
	}
}

function isError<T>(resp: FunctionsResponse<T>): resp is FunctionsHttpErrorModel {
	return (
		(resp as FunctionsHttpErrorModel)?.code !== undefined &&
		(resp as FunctionsHttpErrorModel)?.httpErrorCode !== undefined
	);
}
