import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'snapshotSortBy' })
export class SnapshotSortByPipe implements PipeTransform {
	transform(items: any[], key: string, reverse: boolean = false): any[] {
		return items.sort((a, b) => {
			const aKey = a.snapshot[key];
			const bKey = b.snapshot[key];
			return reverse ? bKey - aKey : aKey - bKey;
		});
	}
}
