import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'scorecardColNumber' })
export class ScorecardColumnNumberPipe implements PipeTransform {
	// these numbers represent the number plus one to account for the add card
	transform(scorecards: unknown[]): number {
		if (scorecards.length < 2) return 6;
		else if ([2, 4, 5].includes(scorecards.length)) return 4;
		else return 3;
	}
}
