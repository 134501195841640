import { Injectable } from '@angular/core';
import { AngularFirestore, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';

/* eslint-disable no-await-in-loop */
import firebase from 'firebase/compat/app';
import { firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';

import { ChannelDAO, PackageDAO, StakeholderDAO } from '../dao';
import { MarketAttributeDAO } from '../dao/market-attribute.dao';
import { Campaign, Program } from '../models';
import { Path, toDotNotation } from '../utils';

@Injectable()
export class RecursiveDeleteService {
	public constructor(private afs: AngularFirestore) {}

	public async deleteMarketAttributes(marketAttributes: MarketAttributeDAO[]): Promise<void[]> {
		return Promise.all(marketAttributes.map((marketAttribute) => marketAttribute.ref.delete()));
	}

	public async deleteStakeholders(stakeholders: StakeholderDAO[]): Promise<void[]> {
		return Promise.all(stakeholders.map((stakeholder) => this.deleteStakeholder(stakeholder)));
	}

	public async deleteStakeholder(stakeholder: StakeholderDAO): Promise<void> {
		const batch = firebase.firestore().batch();

		const stakeholderAttributes = await firstValueFrom(stakeholder.stakeholderAttributes.pipe(first()));
		stakeholderAttributes.forEach((stakeholderAttribute) => {
			batch.delete(stakeholderAttribute.ref);
		});

		const { tenantId, strategyId, revenueStreamId } = Path.getIds(stakeholder.path);
		const programsDocs = await this.getProgramsForRevenueStream(tenantId, strategyId, revenueStreamId);
		programsDocs.forEach((programDoc) => {
			const data = programDoc.data();
			const stakeholderIndex = data.stakeholders ? data.stakeholders.findIndex((x) => x.id === stakeholder.id) : -1;
			if (stakeholderIndex === -1) {
				return;
			}
			const update = {
				stakeholders: firebase.firestore.FieldValue.arrayRemove(stakeholder.ref) as any,
			} as Program;
			batch.update(programDoc.ref, update);
		});

		batch.delete(stakeholder.ref);
		await batch.commit();
	}

	public async deletePackages(packages: PackageDAO[]): Promise<void[]> {
		return Promise.all(packages.map((rsPackage) => this.deletePackage(rsPackage)));
	}

	public async deletePackage(rsPackage: PackageDAO): Promise<void> {
		const batch = firebase.firestore().batch();

		const { tenantId, strategyId, revenueStreamId } = Path.getIds(rsPackage.path);
		const programsDocs = await this.getProgramsForRevenueStream(tenantId, strategyId, revenueStreamId);
		programsDocs.forEach((programDoc) => {
			const data = programDoc.data();
			const packageIndex = data.packages ? data.packages.findIndex((x) => x.id === rsPackage.id) : -1;
			if (packageIndex === -1) {
				return;
			}
			const update = {
				packages: firebase.firestore.FieldValue.arrayRemove(rsPackage.ref) as any,
			} as Program;
			batch.update(programDoc.ref, update);
		});

		batch.delete(rsPackage.ref);
		await batch.commit();
	}

	private async getProgramsForRevenueStream(
		tenantId: string,
		strategyId: string,
		revenueStreamId: string
	): Promise<QueryDocumentSnapshot<Program>[]> {
		const programsResults = await firstValueFrom(
			this.afs.collection<Program>(Path.programs(tenantId, strategyId, revenueStreamId)).get()
		);
		return programsResults.docs;
	}

	public async deleteChannel(channel: ChannelDAO): Promise<void> {
		const batch = firebase.firestore().batch();

		const { tenantId, strategyId } = Path.getIds(channel.path);
		const campaignDocs = await this.getCampaignsWithChannel(tenantId, strategyId, channel.id);
		campaignDocs.forEach((campaignDoc) => {
			const update = {
				channels: { [channel.id]: firebase.firestore.FieldValue.delete() as any },
			} as Campaign;
			batch.update(campaignDoc.ref, toDotNotation(update));
		});

		batch.delete(channel.ref);
		await batch.commit();
	}

	private async getAllCampaigns(
		tenantId: string,
		strategyId: string
	): Promise<firebase.firestore.QueryDocumentSnapshot[]> {
		const db = firebase.firestore();
		const campaigns = [];
		const streamDataPath = Path.streamDataCollection(tenantId, strategyId);
		const streamDataResult = await db.collection(streamDataPath).get();
		const revenueStreamIds = streamDataResult.docs.map((doc) => doc.ref.id);
		for (const revenueStreamId of revenueStreamIds) {
			const programsPath = Path.programs(tenantId, strategyId, revenueStreamId);
			const programsResult = await db.collection(programsPath).get();
			const programIds = programsResult.docs.map((doc) => doc.ref.id);
			for (const programId of programIds) {
				const campaignsPath = Path.campaigns(tenantId, strategyId, revenueStreamId, programId);
				const campaignsResult = await db.collection(campaignsPath).get();
				campaigns.push(...campaignsResult.docs);
			}
		}
		return campaigns;
	}

	private async getCampaignsWithChannel(
		tenantId: string,
		strategyId: string,
		channelId: string
	): Promise<firebase.firestore.QueryDocumentSnapshot[]> {
		const campaignsResult = await this.getAllCampaigns(tenantId, strategyId);
		return campaignsResult.filter((doc) => {
			const { channels } = doc.data() as Campaign;
			return channels && Object.keys(channels).includes(channelId);
		});
	}
}
