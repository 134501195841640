import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { LogService } from '@app/shared/core';
import { PersistenceService } from '@app/shared/data';

import { unmaskFloat, unmaskNumber } from '../../utils';
import { InputComponent } from '../input.component';

@Component({
	selector: 'ayeq-percent-input',
	template: `
		<mat-form-field appearance="outline" [ngClass]="fieldClass">
			<div class="position-relative">
				<input
					name="{{ name }}"
					[formControl]="control"
					matInput
					[mask]="mask"
					suffix="%"
					[dropSpecialCharacters]="false"
					[allowNegativeNumbers]="allowNegative"
					thousandSeparator=","
					(keydown.enter)="$any($event).target.blur()"
				/>
				<div class="actual"><ng-content select="[actual]"></ng-content></div>
			</div>
		</mat-form-field>
	`,
	styleUrls: ['./actual-value.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PercentInputComponent extends InputComponent<any, number, string> {
	@Input() fieldClass: string | string[] | Set<string> | { [klass: string]: any } | null | undefined;
	@Input() allowDecimals = false;
	@Input() allowNegative = false;
	@Input() unbounded = false;

	get mask() {
		if (this.unbounded) return this.allowDecimals ? 'separator.2' : 'separator';
		else return this.allowDecimals ? 'percent.2' : 'percent';
	}

	constructor(log: LogService, persistenceService: PersistenceService) {
		super(log, persistenceService, 'blur');
	}

	protected unmask(value: any): number {
		return this.allowDecimals ? unmaskFloat(value) : unmaskNumber(value);
	}
}
