import { Quarter } from '../enums';

export interface StaffingModel {
	oppTeam: StaffingOppTeam;
	qualTeam: StaffingQualTeam;
}

interface StaffingOppTeam extends StaffingModelDivision {
	hoursPerWeek: number;
}

interface StaffingQualTeam extends StaffingModelDivision {
	touchesPerDay: number;
}

export interface StaffingModelDivision {
	daysOff: number;
	requirementEffort: {
		[stageId: string]: {
			[index: number | string]: number; // Either the requirement index or the stageId itself in the case no requirements exist
		};
	};
	additionalWork: {
		[stageId: string]: {
			[index: number]: StaffingAdditionalWork;
		};
	};
	responsibilities: {
		[index: number]: StaffingResponsibility;
	};
}

export interface StaffingAdditionalWork {
	name: string;
	effort: number;
}

export interface StaffingResponsibility {
	name: string;
	[Quarter.Q1]: number;
	[Quarter.Q2]: number;
	[Quarter.Q3]: number;
	[Quarter.Q4]: number;
}
