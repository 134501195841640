<h1 mat-dialog-title>
	There was an error with the request. You may try again after clicking the refresh button below.
</h1>
<div mat-dialog-content>
	<p>
		We've been alerted to this issue and we are working on it now. If you have any questions please reach out to us at
		<a href="mailto:support@aye-q.com" target="_blank">support&#64;aye-q.com</a>.
	</p>
</div>
<div mat-dialog-actions align="end">
	<button mat-button color="warn" (click)="logout()">Log Out</button>
	<button mat-button color="primary" (click)="refresh()">Refresh</button>
</div>
