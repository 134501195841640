import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
	selector: 'ayeq-button-navigator',
	template: `
		<button (click)="goToRoute()" mat-raised-button color="primary" [ngClass]="buttonClasses">
			<ng-content></ng-content>
		</button>
	`,
	encapsulation: ViewEncapsulation.None,
})
export class ButtonNavigatorComponent {
	@Input() targetRoute: string;
	@Input() relativeRoute: string[];
	@Input() queryParams: Params;
	@Input() buttonClasses: string;
	constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

	goToRoute() {
		if (this.targetRoute) {
			this.goToTargetRoute();
		} else if (this.relativeRoute) {
			this.goToRelativeRoute();
		}
	}

	goToTargetRoute() {
		let query = '?';
		if (this.queryParams) {
			for (const [key, value] of Object.entries(this.queryParams)) {
				query += `${key}=${value}&`;
			}
		}
		this.router.navigateByUrl(`${this.targetRoute}${query}`, {});
	}

	goToRelativeRoute() {
		this.router.navigate(this.relativeRoute, { relativeTo: this.activatedRoute, queryParams: this.queryParams });
	}
}
