import { TrackingStatus } from '../enums';

export interface Scorecard {
	name: string;
	performance?: string;
	progress?: number;
	status?: TrackingStatus;
	kpiStatement?: string;
	annotation?: string;
	order: number;
}
