import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { BaseDAO } from '@app/shared/data';

export function mapFlatChild<T, D extends BaseDAO<any>>(
	selector: (model: T) => D[]
): (sources: Observable<T[]>) => Observable<D[]> {
	return (source: Observable<T[]>) =>
		source.pipe(
			map((x) => {
				const props = x.map((y) => selector(y));
				return Array.prototype.concat.apply([], props) as D[];
			})
		);
}

export function switchFlatChild<T, D extends BaseDAO<any>>(
	selector: (model: T) => Observable<D[]>
): (sources: Observable<T[]>) => Observable<D[]> {
	return (source: Observable<T[]>) =>
		source.pipe(
			switchMap((x) => combineLatest(x.map((y) => selector(y)))),
			map((x) => Array.prototype.concat.apply([], x) as D[])
		);
}
