import { FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

export class FormArrayUtil {
	public static updateFormArrayOptions(
		form: FormGroup,
		controlName: string,
		optionCount: number,
		validatorOrOpts?: ValidatorFn[]
	): void {
		let formArray = form.get(controlName) as FormArray;
		if (formArray != null && formArray.length === optionCount) return;

		formArray = formArray || new FormArray([], validatorOrOpts);
		if (formArray.length < optionCount) {
			// add
			for (let i = formArray.length; i < optionCount; i++) {
				formArray.push(new FormControl());
			}
		} else {
			// remove
			for (let i = formArray.length; i > optionCount; i--) {
				formArray.push(new FormControl());
			}
		}

		form.setControl(controlName, formArray);
	}

	public static updateFormArrayValues<To, Ts>(
		form: FormGroup,
		controlName: string,
		options: To[],
		selected: Ts[],
		compare: (o: To, s: Ts) => boolean
	): void {
		const formArray = form.get(controlName) as FormArray;
		if (formArray == null) return;

		const values = formArray.controls.map((control, i) => {
			const option = options[i];
			if (option == null) {
				return false;
			}
			if (selected == null) {
				return false;
			}
			return selected.find((s) => compare(options[i], s)) != null;
		});

		formArray.setValue(values, { emitEvent: false });
	}

	public static getFormArrayValues<To, Tv>(
		form: FormGroup,
		controlName: string,
		options: To[],
		getValue: (o: To) => Tv
	): Tv[] {
		const formArray = form.get(controlName) as FormArray;
		if (formArray == null) return [];

		return options.reduce((acc, curr, i) => {
			if (formArray.at(i).value) {
				const value = getValue(curr);
				acc.push(value);
			}
			return acc;
		}, []);
	}
}
