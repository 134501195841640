import { map, Observable } from 'rxjs';

import { KpiBox } from '@app/shared/shared';

import { Scorecard } from '../models';
import { BaseDAO } from './base.dao';

export class CustomScorecardDAO extends BaseDAO<Scorecard> {
	get kpiBox(): Observable<KpiBox> {
		return this.stream.pipe(map((s) => ({ ...s, isInitiative: false })));
	}
}
