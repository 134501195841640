import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { LogService } from '@app/shared/core';
import { PersistenceService } from '@app/shared/data';

import { InputComponent } from '../input.component';

@Component({
	selector: 'ayeq-text-area-input',
	template: `
		<mat-form-field appearance="outline" [ngClass]="fieldClass">
			<textarea
				name="{{ name }}"
				[formControl]="control"
				matInput
				cdkTextareaAutosize
				[cdkAutosizeMinRows]="rows"
				placeholder="{{ placeholder }}"
			>
			</textarea>
		</mat-form-field>
	`,
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAreaInputComponent extends InputComponent<any, string, string> {
	@Input() placeholder = '';
	@Input() rows = 5;
	@Input() fieldClass: string | string[] | Set<string> | { [klass: string]: any } | null | undefined;

	constructor(log: LogService, persistenceService: PersistenceService) {
		super(log, persistenceService, 'blur');
	}

	protected unmask(x: any): string {
		return x;
	}
}
