import { AbstractControl, ValidatorFn } from '@angular/forms';

import { isEmptyInputValue } from './shared';

export function unmaskWholeNumber(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		let { value } = control;
		if (isEmptyInputValue(value)) {
			return null; // don't validate empty values to allow optional controls
		}
		if (typeof value === 'string') {
			value = value.replace(/[^0-9.-]/g, '');
		}
		const floatValue = parseFloat(value);
		const intValue = parseInt(value, 10);
		if (!Number.isNaN(floatValue) || !Number.isNaN(intValue)) {
			return floatValue !== intValue ? { wholeNumber: { wholeNumber: intValue, actual: value } } : null;
		}
		return null;
	};
}
