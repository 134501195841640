import { AbstractControl, ValidatorFn } from '@angular/forms';

import { isEmptyInputValue } from './shared';

export function unmaskMax(max: number): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		let { value } = control;
		if (isEmptyInputValue(value) || isEmptyInputValue(max)) {
			return null; // don't validate empty values to allow optional controls
		}
		if (typeof value === 'string') {
			value = value.replace(/[^0-9.-]/g, '');
		}
		const floatValue = parseFloat(value);
		// Controls with NaN values after parsing should be treated as not having a
		// maximum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-max
		return !Number.isNaN(floatValue) && floatValue > max ? { max: { max: floatValue, actual: value } } : null;
	};
}
