import { Observable } from 'rxjs';

import { Stakeholder } from '../models';
import { Path } from '../utils';
import { BaseDAO } from './base.dao';
import { StakeholderAttributeDAO } from './stakeholder-attribute.dao';

export class StakeholderDAO extends BaseDAO<Stakeholder> {
	private _stakeholderAttributes: Observable<StakeholderAttributeDAO[]>;

	public get stakeholderAttributes(): Observable<StakeholderAttributeDAO[]> {
		if (this._stakeholderAttributes == null) {
			const { tenantId, strategyId, revenueStreamId, stakeholderId } = Path.getIds(this.path);
			const path = Path.stakeholderAttributes(tenantId, strategyId, revenueStreamId, stakeholderId);
			this._stakeholderAttributes = this.initCollection(StakeholderAttributeDAO, path);
		}
		return this._stakeholderAttributes;
	}
}
