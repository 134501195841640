import { Component } from '@angular/core';

import { environment } from '@env/environment';

import { AuthService } from './shared/shared/services';

@Component({
	selector: 'ayeq-root',
	template: `
		<router-outlet></router-outlet>
		<span title="{{ envName }}" style="display: none;"></span>
	`,
})
export class AppComponent {
	public envName = environment.name;
	constructor(private _auth: AuthService) {
		this._auth.init();
	}
}
