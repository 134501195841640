import { Observable } from 'rxjs';

import { AssetType } from '../models/asset-type';
import { Path } from '../utils';
import { AssetDAO } from './asset.dao';
import { BaseDAO } from './base.dao';

export class AssetTypeDAO extends BaseDAO<AssetType> {
	private _assets: Observable<AssetDAO[]>;

	public get assets(): Observable<AssetDAO[]> {
		if (this._assets == null) {
			const { tenantId, strategyId, assetTypeId } = Path.getIds(this.path);
			const path = Path.assets(tenantId, strategyId, assetTypeId);
			this._assets = this.initCollection(AssetDAO, path);
		}
		return this._assets;
	}
}
