import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { ActionItemStatus } from '../enums';
import { ActionItem } from '../models';
import { Path } from '../utils/path';
import { BaseRepository } from './base-repository.service';

@Injectable()
export class CollaborationMeetingActionItemRepository extends BaseRepository<ActionItem> {
	constructor(private afs: AngularFirestore) {
		super();
	}

	public getImperativeActionItems(
		tenantId: string,
		startDate?: firebase.firestore.Timestamp,
		endDate?: firebase.firestore.Timestamp
	): Observable<ActionItem[]> {
		const actionItemsPath = Path.actionItems(tenantId);
		return combineLatest([
			this.getImperativeActionItemsUnresolvedBeforeMeeting(actionItemsPath, startDate),
			this.getImperativeActionItemsUpdatedInMeeting(actionItemsPath, startDate, endDate),
		]).pipe(
			debounceTime(0),
			map((x) => this.mapUnique(x))
		);
	}

	private getImperativeActionItemsUnresolvedBeforeMeeting(
		actionItemsPath: string,
		startDate?: firebase.firestore.Timestamp
	): Observable<ActionItem[]> {
		return this.afs
			.collection<ActionItem>(actionItemsPath, (ref) => {
				let query = ref.where('status', 'in', [
					ActionItemStatus.inProgress,
					ActionItemStatus.notStarted,
					ActionItemStatus.unreported,
				]);
				if (startDate) {
					query = query.orderBy('createdOn').where('createdOn', '<', startDate);
				}
				return query.orderBy('imperative');
			})
			.snapshotChanges()
			.pipe(map((x) => this.mapListSnapshot(x)));
	}

	private getImperativeActionItemsUpdatedInMeeting(
		actionItemsPath: string,
		startDate?: firebase.firestore.Timestamp,
		endDate?: firebase.firestore.Timestamp
	): Observable<ActionItem[]> {
		return this.afs
			.collection<ActionItem>(actionItemsPath, (ref) => {
				let query: firebase.firestore.Query<firebase.firestore.DocumentData> = ref;
				if (endDate) {
					query = query.orderBy('createdOn').where('createdOn', '<', endDate);
				}
				return query.orderBy('imperative');
			})
			.snapshotChanges()
			.pipe(
				map((x) =>
					// we can't query on two separate dates so filter on the other here
					this.mapListSnapshot(x).filter(
						(y) => startDate == null || (y.statusUpdatedOn && y.statusUpdatedOn > startDate) || y.createdOn > startDate
					)
				)
			);
	}

	public getIssueActionItems(
		tenantId: string,
		startDate?: firebase.firestore.Timestamp,
		endDate?: firebase.firestore.Timestamp
	): Observable<ActionItem[]> {
		const actionItemsPath = Path.actionItems(tenantId);
		return combineLatest([
			this.getIssueActionItemsUnresolvedBeforeEndOfMeeting(actionItemsPath, endDate),
			this.getIssueActionItemsResolvedInMeeting(actionItemsPath, startDate, endDate),
		]).pipe(map((x) => this.mapUnique(x)));
	}

	private getIssueActionItemsUnresolvedBeforeEndOfMeeting(
		actionItemsPath: string,
		endDate?: firebase.firestore.Timestamp
	): Observable<ActionItem[]> {
		return this.afs
			.collection<ActionItem>(actionItemsPath, (ref) => {
				let query = ref.where('status', 'in', [
					ActionItemStatus.inProgress,
					ActionItemStatus.notStarted,
					ActionItemStatus.unreported,
				]);
				if (endDate) {
					query = query.orderBy('createdOn').where('createdOn', '<', endDate);
				}
				return query.orderBy('issue');
			})
			.snapshotChanges()
			.pipe(map((x) => this.mapListSnapshot(x)));
	}

	private getIssueActionItemsResolvedInMeeting(
		actionItemsPath: string,
		startDate?: firebase.firestore.Timestamp,
		endDate?: firebase.firestore.Timestamp
	): Observable<ActionItem[]> {
		return this.afs
			.collection<ActionItem>(actionItemsPath, (ref) => {
				let query = ref.where('status', 'in', [ActionItemStatus.completed, ActionItemStatus.abandoned]);
				if (startDate || endDate) {
					query = query.orderBy('statusUpdatedOn');
				}
				if (startDate) {
					query = query.where('statusUpdatedOn', '>', startDate);
				}
				if (endDate) {
					query = query.where('statusUpdatedOn', '<', endDate);
				}
				return query.orderBy('issue');
			})
			.snapshotChanges()
			.pipe(map((x) => this.mapListSnapshot(x)));
	}
}
