import { DocumentReference } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';

export interface Program {
	name: string;
	budget: number;
	createdAt: firebase.firestore.Timestamp;
	packages: DocumentReference[];
	stakeholders: DocumentReference[];
}
