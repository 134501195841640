import { Observable } from 'rxjs';

import { Imperative } from '../models';
import { BaseDAO } from './base.dao';
import { InitiativeDAO } from './initiative.dao';
import { UserDAO } from './user.dao';

export class ImperativeDAO extends BaseDAO<Imperative> {
	public get owner(): Observable<UserDAO> {
		return this.getSubDocumentDao((x) => x.owner, UserDAO);
	}

	public get initiative(): Observable<InitiativeDAO> {
		return this.getSubDocumentDao((x) => x.initiative, InitiativeDAO);
	}

	public get initiativeId(): string {
		return this.snapshot.initiative.id;
	}
}
