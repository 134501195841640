import { Observable } from 'rxjs';

import { Segment } from '../models/segment';
import { SegmentValue } from '../models/segment-value';
import { FsCollection } from '../utils';
import { BaseDAO } from './base.dao';

export class SegmentDao extends BaseDAO<Segment> {
	private _values: Observable<BaseDAO<SegmentValue>[]>;

	get values(): Observable<BaseDAO<SegmentValue>[]> {
		if (!this._values) {
			const path = `${this.path}/${FsCollection.SegmentValues}`;
			this._values = this.initCollection(BaseDAO<SegmentValue>, path, (ref) => ref.orderBy('order'));
		}
		return this._values;
	}
}
