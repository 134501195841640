import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { EnvironmentConfig, LoggingConfig } from '@env/environment-config';

import { LogLevel } from './log-level.model';

/**
 * Allow the user to modify the log settings live in the browser.
 * @export
 * @class LogSettingsService
 */
@Injectable()
export class LogSettingsService {
	private logConfig: LoggingConfig;
	private configChanges: Subject<LoggingConfig>;

	constructor(environmentConfig: EnvironmentConfig) {
		this.logConfig = environmentConfig.logging;
		const configClone = <LoggingConfig>{ ...this.logConfig };
		this.configChanges = new BehaviorSubject<LoggingConfig>(configClone);

		this.init();
	}

	/**
	 * Observe changes to the logging config.
	 * @readonly
	 * @type {Observable<LoggingConfig>}
	 * @memberof LogSettingsService
	 */
	public get config(): Observable<LoggingConfig> {
		return this.configChanges.asObservable();
	}

	/**
	 * Modify the log level.
	 * @memberof LogSettingsService
	 */
	public set level(logLevel: LogLevel) {
		this.logConfig.logLevel = logLevel;
		this.updateConfig();
	}

	/**
	 * Set the zones.
	 * @memberof LogSettingsService
	 */
	public set zones(zones: string[]) {
		this.logConfig.zones = new Set<string>(zones);
		this.updateConfig();
	}

	/**
	 * Add a zone.
	 * @param {string} zone
	 * @returns {void}
	 * @memberof LogSettingsService
	 */
	public addZone(zone: string): void {
		if (this.logConfig.zones.has(zone)) {
			return;
		}
		this.logConfig.zones.add(zone);
		this.updateConfig();
	}

	/**
	 * Remove a zone;
	 * @param {string} zone
	 * @returns {void}
	 * @memberof LogSettingsService
	 */
	public removeZone(zone: string): void {
		if (!this.logConfig.zones.delete(zone)) {
			return;
		}
		this.updateConfig();
	}

	/**
	 * Publish a change for the config.
	 * @private
	 * @memberof LogSettingsService
	 */
	private updateConfig(): void {
		const configClone = <LoggingConfig>{ ...this.logConfig };
		this.configChanges.next(configClone);
	}

	/**
	 * Expose this class to the window.
	 * @private
	 * @returns {void}
	 * @memberof LogSettingsService
	 */
	/* eslint-disable @typescript-eslint/dot-notation */
	private init(): void {
		if (window['ayeq'] && window['ayeq'].log) {
			return;
		}
		if (!window['ayeq']) {
			window['ayeq'] = {};
		}
		window['ayeq'].log = this;
	}
	/* eslint-enable @typescript-eslint/dot-notation */
}
