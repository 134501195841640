import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authGuard, unauthedGuard } from './shared/auth/guards';

const routes: Routes = [
	{
		path: 'login',
		redirectTo: 'auth/login',
	},
	{
		path: 'auth',
		loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
		canLoad: [unauthedGuard],
	},
	{
		path: '',
		loadChildren: () => import('./features/portal/portal.module').then((m) => m.PortalModule),
		canLoad: [authGuard],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule],
})
export class AppRoutingModule {}
