export interface WeekValue {
	month: number;
	dayOfMonth: number;
	fromDate: Date;
	toDate: Date;
}
export interface Week {
	view: string;
	value: WeekValue;
}
