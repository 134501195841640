import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { firstValueFrom } from 'rxjs';

export interface ConfirmationData {
	text: string;
	checkBoxText?: string;
	confirmText: string;
}

export interface ConfirmationResult {
	confirm: boolean;
}

@Component({
	selector: 'ayeq-confirmation-dialog',
	template: `
		<div mat-dialog-content>
			<p>{{ data.text }}</p>
			<mat-checkbox *ngIf="data.checkBoxText" color="primary" [(ngModel)]="checked">{{
				data.checkBoxText
			}}</mat-checkbox>
		</div>
		<div mat-dialog-actions align="end">
			<button mat-raised-button color="secondary" (click)="close(false)">Cancel</button>
			<button mat-raised-button color="primary" [disabled]="!canConfirm()" (click)="close(true)">
				{{ data.confirmText }}
			</button>
		</div>
	`,
})
export class ConfirmationDialogComponent {
	public checked = false;
	public requiresCheckBox = false;
	constructor(
		public dialogRef: MatDialogRef<ConfirmationDialogComponent, ConfirmationResult>,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmationData
	) {
		if (this.data.checkBoxText) {
			this.requiresCheckBox = true;
		}
	}

	canConfirm() {
		if (this.requiresCheckBox) {
			return this.checked;
		}
		return true;
	}

	close(value: boolean): void {
		this.dialogRef.close({ confirm: value });
	}
}

export function openConfirmationDialog(
	dialog: MatDialog,
	data: ConfirmationData,
	config?: MatDialogConfig
): Promise<ConfirmationResult> {
	return firstValueFrom(
		dialog
			.open<ConfirmationDialogComponent, ConfirmationData, ConfirmationResult>(ConfirmationDialogComponent, {
				width: '350px',
				autoFocus: false,
				data,
				...(config ?? {}),
			})
			.beforeClosed()
	);
}
