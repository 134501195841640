import { addQuarters, differenceInYears } from 'date-fns';

import { AnalyticsSheets } from './analytics-sheet';

export interface StrategyDates {
	year: number;
	startingQuarter?: number;
	startingMonth?: number; // Note this is the JS month; i.e. January === 0
}

export interface Strategy extends StrategyDates {
	vision?: string;
	mission?: string;
	brandStatement?: string;
	brandAttributes?: string[];
	coreValues?: string[];
	analyticsSheets?: AnalyticsSheets;
	analyticsGraph?: string;
	showAnnotations?: boolean;
	gtmProgress?: GtmProgressType[];
	gtmPlanSummary?: string;
}

export interface StrategyOption {
	label: string;
	year: number;
}

export type GtmProgressType =
	| 'FOUNDATION'
	| 'MARKETS'
	| 'OFFERINGS'
	| 'REVENUE_STREAMS'
	| 'TARGETS'
	| 'INITIATIVES'
	| 'SCORECARDS'
	| 'PROCESS'
	| 'MODEL';

export function strategyStartDate(strategy: StrategyDates): Date {
	let startDate = new Date(strategy.year, 0);
	if (strategy.startingQuarter > 0) {
		startDate = addQuarters(startDate, strategy.startingQuarter - 1);
	} else if (strategy.startingMonth > 0) {
		startDate = new Date(strategy.year, strategy.startingMonth);
	}
	return startDate;
}

export function isInStrategyYear(strategy: StrategyDates): boolean {
	const startDate = strategyStartDate(strategy);
	return differenceInYears(new Date(), startDate) === 0;
}

export function toStrategyOption(strategy: StrategyDates, yearOverride?: number): StrategyOption {
	const year = yearOverride ?? strategy.year;
	let label = `${year}`;
	if (strategy.startingQuarter > 1 || strategy.startingMonth > 0) {
		label = `${year}/${year + 1}`;
	}
	return { year, label };
}

export function equalByDate(a: StrategyDates, b: StrategyDates) {
	return a.year === b.year && a.startingMonth === b.startingMonth && a.startingQuarter === b.startingQuarter;
}

export function isGtmStepComplete(strategy: Strategy, type: GtmProgressType) {
	return strategy.gtmProgress?.includes(type) ?? false;
}

export function isGtmFinished(strategy: Strategy) {
	return strategy.gtmProgress?.includes('MODEL') ?? false;
}
