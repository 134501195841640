import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { LogService } from '@app/shared/core';
import { PersistenceService } from '@app/shared/data';

import { InputComponent } from '../input.component';

@Component({
	selector: 'ayeq-checkbox-input',
	template: `
		<mat-checkbox [name]="name" color="primary" [formControl]="control">
			{{ label }}
		</mat-checkbox>
	`,
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxInputComponent<TValue> extends InputComponent<any, TValue, string> {
	@Input({ required: true }) label: string;

	constructor(log: LogService, persistenceService: PersistenceService) {
		super(log, persistenceService, 'change');
	}

	protected unmask(x: any): TValue {
		return x;
	}
}
