import { map, Observable } from 'rxjs';

import { KpiBox } from '@app/shared/shared';

import { Initiative } from '../models';
import { BaseDAO } from './base.dao';
import { UserDAO } from './user.dao';

export class InitiativeDAO extends BaseDAO<Initiative> {
	public get owner(): Observable<UserDAO> {
		return this.getSubDocumentDao((x) => x.owner, UserDAO);
	}

	public get ownerId(): string {
		return this.snapshot.owner.id;
	}

	get kpiBox(): Observable<KpiBox> {
		return this.stream.pipe(map((s) => ({ ...s, isInitiative: true })));
	}
}
