import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Initiative } from '../models';
import { Path } from '../utils/path';
import { BaseRepository } from './base-repository.service';

@Injectable()
export class InitiativeRepository extends BaseRepository<Initiative> {
	constructor(private afs: AngularFirestore) {
		super();
	}

	public getInitiatives(tenantId: string, strategyId: string): Observable<Initiative[]> {
		const path = Path.initiatives(tenantId, strategyId);
		return this.afs
			.collection<Initiative>(path)
			.snapshotChanges()
			.pipe(map((x) => this.mapListSnapshot(x)));
	}
}
