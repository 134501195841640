<mat-form-field appearance="outline" class="w-100" *ngIf="config">
	<input
		#input
		id="autocomplete-filter-select"
		type="text"
		[placeholder]="placeholder"
		[attr.maxlength]="maxlength ? maxlength : null"
		matInput
		[formControl]="childControl"
		[errorStateMatcher]="parentErrorStateMatcher"
		[matAutocomplete]="autocompleteFilterSelect"
		(blur)="onSelect.next(null)"
		(keydown.enter)="$event.preventDefault(); matAutocompleteInput.closePanel(); input.blur()"
		#matAutocompleteInput="matAutocompleteTrigger"
	/>
	<mat-autocomplete
		#autocompleteFilterSelect="matAutocomplete"
		[displayWith]="matDisplay"
		(optionSelected)="input.blur(); onSelect.next($event.option.value)"
	>
		<mat-option *ngFor="let option of filteredOptions | async; trackBy: indexTracker" [value]="option">
			{{ config.display(option) }}
		</mat-option>
	</mat-autocomplete>
	<mat-error *ngIf="getMessagedError()">{{ getMessagedError() }}</mat-error>
</mat-form-field>
