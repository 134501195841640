import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { combineLatest, distinctUntilChanged, map, Observable, switchMap } from 'rxjs';

import { FsCollection, Path } from '@app/shared/data';
import { BookingsPlanMetric, BookingsToDateMetric, ForecastMetric, MetricDoc } from '@app/shared/data/models/metrics';
import { StrategyService } from '@app/shared/shared';
import { TenantService } from '@app/shared/shared/services/tenant.service';

@Injectable()
export class MetricsService {
	constructor(
		private afs: AngularFirestore,
		private strategyService: StrategyService
	) {}

	private getMetric<T>(metric: MetricDoc): Observable<T> {
		const tenantMetric = this.afs
			.doc<T>(`${Path.tenant(TenantService.tenantId)}/${FsCollection.Metrics}/${metric}`)
			.valueChanges();
		const strategyMetric = this.strategyService.selectedStrategy.pipe(
			distinctUntilChanged((a, b) => a.id === b.id),
			switchMap((s) =>
				this.afs
					.doc<T>(`${Path.strategy(TenantService.tenantId, s.id)}/${FsCollection.Metrics}/${metric}`)
					.valueChanges()
			)
		);

		return combineLatest([tenantMetric, strategyMetric]).pipe(map(([t, s]) => s ?? t));
	}

	getBookingsToDate(metric: MetricDoc.BookingsQtd | MetricDoc.BookingsYtd): Observable<BookingsToDateMetric> {
		return this.getMetric<BookingsToDateMetric>(metric).pipe(
			map((x) => (x ? { current: x.current ?? 0, goal: x.goal ?? 0 } : undefined))
		);
	}

	getForecast() {
		return this.getMetric<ForecastMetric>(MetricDoc.Forecast);
	}

	getBookingsPlan() {
		return this.getMetric<BookingsPlanMetric>(MetricDoc.BookingsPlan);
	}
}
