export enum CssColor {
	text = '#666',
	blue = '#00a1e0',
	green = '#51ad97',
	yellow = '#d0cc16',
	orange = '#fe893c',
	completed = blue,
	onTrack = green,
	atRisk = yellow,
	offTrack = orange,
	darkBlue = '#036b93',
	darkGreen = '#2f685a',
}

export const CHART_COLOR_PALETTE = [
	'#51AD98',
	'#D0CC16',
	'#FE893D',
	'#00A1E0',
	'#A16090',
	'#ED7373',
	'#5AC2D0',
	'#86B75C',
	'#EDB54C',
	'#036B93',
	'#8D88CE',
	'#ED9473',
	'#CB6DB2',
];
