import { DocumentReference } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';

import { IssueStatus, Priority } from '../enums';
import { Identity } from './identity';

export interface Issue extends Identity {
	createdBy: DocumentReference;
	createdOn: firebase.firestore.Timestamp;
	name: string;
	description: string;
	resolution?: IssueStatus;
	priority?: Priority;
	createdInMeeting?: boolean;
	resolutionSetOn?: firebase.firestore.Timestamp;
}
