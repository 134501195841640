import { Observable } from 'rxjs';

import { Campaign } from '../models';
import { AssetTypeDAO } from './asset-type.dao';
import { AssetDAO } from './asset.dao';
import { BaseDAO } from './base.dao';

export class CampaignDAO extends BaseDAO<Campaign> {
	public get asset(): Observable<AssetDAO> {
		return this.getSubDocumentDao((x) => x.asset, AssetDAO);
	}

	public get assetType(): Observable<AssetTypeDAO> {
		return this.getSubDocumentDao((x) => x.assetType, AssetTypeDAO);
	}
}
