import { QuarterValue } from '../enums';
import { SalesClosureEstimate } from './sales-closure-estimate';

export interface BookingsModel {
	averageDealSize: number;
	salesClosureEstimates: SalesClosureEstimates;
	oppsAcceptedOption: OpportunitiesAcceptedOption;
	manualOpportunitiesForQualification: QuarterValue;
	qualificationPaths: {
		[key: string]: BookingsQualificationPath;
	};
}

export interface ConversionRates {
	[qualStage: string]: number;
}

export interface SalesClosureEstimates {
	[oppStage: string]: SalesClosureEstimate;
}

export interface BookingsQualificationPath {
	opportunities: QuarterValue;
	conversionRates: ConversionRates;
	leadGeneration: QuarterValue;
}

export enum OpportunitiesAcceptedOption {
	EXACT = 'Exact Sales Needs',
	MANUAL = 'Manual',
}
