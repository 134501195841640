import { combineLatest, map, Observable } from 'rxjs';

import { BookingsTarget, RevenueStream, Strategy } from '../models';
import { StreamData } from '../models/stream-data';
import { FsCollection, Path } from '../utils';
import { BaseDAO } from './base.dao';
import { BookingsModelDAO } from './bookings-model.dao';
import { MarketAttributeDAO } from './market-attribute.dao';
import { PackageDAO } from './package.dao';
import { ProcessDAO } from './process.dao';
import { ProgramDAO } from './program.dao';
import { StaffingModelDAO } from './staffing-model.dao';
import { StakeholderDAO } from './stakeholder.dao';

export class StreamDataDAO extends BaseDAO<StreamData> {
	private _attributes: Observable<MarketAttributeDAO[]>;
	private _programs: Observable<ProgramDAO[]>;
	private _packages: Observable<PackageDAO[]>;
	private _stakeholders: Observable<StakeholderDAO[]>;

	public get bookingsModelId(): string {
		return this.snapshot.bookingsModel != null && this.snapshot.bookingsModel.id;
	}

	public get bookingsModel(): Observable<BookingsModelDAO> {
		return this.getSubDocumentDao((x) => x.bookingsModel, BookingsModelDAO);
	}

	public get sandboxBookingsModelId(): string {
		return this.snapshot.sandboxBookingsModel != null && this.snapshot.sandboxBookingsModel.id;
	}

	public get sandboxBookingsModel(): Observable<BookingsModelDAO> {
		return this.getSubDocumentDao((x) => x.sandboxBookingsModel, BookingsModelDAO);
	}

	public get actualBookingsModelId(): string {
		return this.snapshot.actualBookingsModel != null && this.snapshot.actualBookingsModel.id;
	}

	public get actualBookingsModel(): Observable<BookingsModelDAO> {
		return this.getSubDocumentDao((x) => x.actualBookingsModel, BookingsModelDAO);
	}

	public get staffingModelId(): string {
		return this.snapshot.staffingModel != null && this.snapshot.staffingModel.id;
	}

	public get staffingModel(): Observable<StaffingModelDAO> {
		return this.getSubDocumentDao((x) => x.staffingModel, StaffingModelDAO);
	}

	public get sandboxStaffingModelId(): string {
		return this.snapshot.sandboxStaffingModel != null && this.snapshot.sandboxStaffingModel.id;
	}

	public get sandboxStaffingModel(): Observable<StaffingModelDAO> {
		return this.getSubDocumentDao((x) => x.sandboxStaffingModel, StaffingModelDAO);
	}

	public get processId(): string {
		return this.snapshot.process != null && this.snapshot.process.id;
	}

	public get process(): Observable<ProcessDAO> {
		return this.getSubDocumentDao((x) => x.process, ProcessDAO);
	}

	public get attributes(): Observable<MarketAttributeDAO[]> {
		if (this._attributes == null) {
			const path = `${this.path}/${FsCollection.MarketAttributes}`;
			this._attributes = this.initCollection(MarketAttributeDAO, path);
		}
		return this._attributes;
	}

	public get programs(): Observable<ProgramDAO[]> {
		if (this._programs == null) {
			const path = `${this.path}/${FsCollection.Programs}`;
			this._programs = this.initCollection(ProgramDAO, path);
		}
		return this._programs;
	}

	public get packages(): Observable<PackageDAO[]> {
		if (this._packages == null) {
			const path = `${this.path}/${FsCollection.Packages}`;
			this._packages = this.initCollection(PackageDAO, path);
		}
		return this._packages;
	}

	public get stakeholders(): Observable<StakeholderDAO[]> {
		if (this._stakeholders == null) {
			const path = `${this.path}/${FsCollection.Stakeholders}`;
			this._stakeholders = this.initCollection(StakeholderDAO, path);
		}
		return this._stakeholders;
	}

	// TODO: this is not good practice - the method should be elsewhere
	getBookingsTarget(isNextYear = false): Observable<BookingsTarget> {
		const { tenantId, strategyId } = Path.getIds(this.path);
		return combineLatest([
			this._afs.doc<RevenueStream>(Path.revenueStream(tenantId, this.id)).get(),
			this._afs.doc<Strategy>(Path.strategy(tenantId, strategyId)).get(),
		]).pipe(map(([rsDoc, stratDoc]) => rsDoc.data().bookingsTargets?.[stratDoc.data().year + (isNextYear ? 1 : 0)]));
	}
}
