import { inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivateFn, Router } from '@angular/router';

import { map, take } from 'rxjs/operators';

import { WebStorage } from '@app/shared/core';
import { LocalStorageKeys } from '@app/shared/shared/constants';

// eslint-disable-next-line func-style
export const unauthedGuard: CanActivateFn = () => {
	const afa = inject(AngularFireAuth);
	const router = inject(Router);

	return afa.user.pipe(
		take(1),
		map((user) => {
			const tenantId = WebStorage.getItem(LocalStorageKeys.TenantId);
			if (user && tenantId) {
				return router.parseUrl('/');
			}
			return true;
		})
	);
};
