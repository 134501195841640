<ng-container *ngIf="issues$ | async as issues">
	<ng-container *ngIf="issues.length; else noIssues">
		<div *ngFor="let issue of issues; let last = last">
			<ayeq-issue-backlog-item class="d-flex" [class.border-bottom]="!last" [issue]="issue"></ayeq-issue-backlog-item>
		</div>
	</ng-container>
	<ng-template #noIssues>
		<p *ngIf="!query" class="mx-5">
			No {{ TermKey.issue | term : true }}. Add {{ TermKey.issue | term : true }} in Strategy Tracking or the
			Collaboration Meeting.
		</p>
		<p *ngIf="query" class="mx-5">No {{ TermKey.issue | term : true }} match the search criteria.</p>
	</ng-template>
</ng-container>
