import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';

const TOOLTIP_CONFIG: MatTooltipDefaultOptions = {
	showDelay: 250,
	hideDelay: 0,
	touchendHideDelay: 1000,
	position: 'above',
};

export const TOOLTIP_CONFIG_PROVIDER = { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: TOOLTIP_CONFIG };
