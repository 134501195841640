/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js';

import { LogLevel } from '@app/shared/core/services/logging/log-level.model';

import { EnvironmentConfig } from './environment-config';

export const environment: EnvironmentConfig = {
	production: false,
	firebase: {
		apiKey: 'AIzaSyB9V2ePSg51KUSiXUW_ng8YA-9LdyLdx5E',
		authDomain: 'ayeq-io-dev.firebaseapp.com',
		databaseURL: 'https://ayeq-io-dev.firebaseio.com',
		projectId: 'ayeq-io-dev',
		storageBucket: 'ayeq-io-dev.appspot.com',
		messagingSenderId: '276368798650',
	},
	logging: {
		logLevel: LogLevel.All,
		zones: new Set<string>([]),
	},
	variables: {
		analyticsHost: 'https://analytics.ayeq.io',
		qlikHost: 'https://qlik.ayeq.io',
		defaultSuperAdminTenantId: '84DDNDhNsogl1mVVq76G',
	},
	name: 'Dev',
};
