import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AnalyticsAuthenticationStatus } from '@app/shared/data/enums';
import { environment } from '@env/environment';

import { FunctionsService } from './functions.service';
import { LogService } from './logging';

const { qlikHost } = environment.variables;

@Injectable()
export class QlikService {
	initializationPromise: Promise<boolean>;

	constructor(
		private functionsService: FunctionsService,
		private http: HttpClient,
		private log: LogService
	) {}

	/**
	 * Makes a request to qlik with a jwt just to allow qlik to set a session cookie for future requests.
	 * We don't care about the response body, just that the authentication occurred.
	 */
	private async setupSession(): Promise<boolean> {
		this.log.debug('Getting Qlik JWT');
		const jwt = await this.functionsService.getQlikJwt();
		if (!jwt) return false;

		this.log.debug('Verifying Qlik authentication');
		return firstValueFrom(
			this.http
				.get(`${qlikHost}/jwt/hub/`, {
					headers: new HttpHeaders({
						Authorization: `Bearer ${jwt}`,
					}),
					withCredentials: true,
					responseType: 'text',
					observe: 'response',
				})
				.pipe(
					catchError(() => of(null)),
					map((response) => response?.ok ?? false)
				)
		);
	}

	/**
	 * Forces user to wait until qlik has been authenticated before returning a modified url that is intended to hit the
	 * virtual proxy that handles requests authenticated with a JWT
	 * @param url intended endpoint in qlik
	 */
	async fetch(url: string): Promise<AnalyticsAuthenticationStatus.Unauthenticated | string> {
		if (this.initializationPromise === undefined) {
			this.initializationPromise = this.setupSession();
		}
		if (await this.initializationPromise) return url?.replace(qlikHost, `${qlikHost}/jwt`);
		return AnalyticsAuthenticationStatus.Unauthenticated;
	}
}
