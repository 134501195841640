import { LogLevel } from '@app/shared/core/services/logging/log-level.model';

export class EnvironmentConfig {
	production: boolean;
	firebase: FirebaseConfig;
	logging: LoggingConfig;
	variables: VariablesConfig;
	name: string;
}

export class LoggingConfig {
	logLevel: LogLevel;
	zones: Set<string>;
}

export class FirebaseConfig {
	apiKey: string;
	authDomain: string;
	databaseURL: string;
	projectId: string;
	storageBucket: string;
	messagingSenderId: string;
}

export class VariablesConfig {
	analyticsHost: string;
	qlikHost: string;
	defaultSuperAdminTenantId: string;
}
