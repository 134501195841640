import { combineLatest, map, Observable } from 'rxjs';

import { BookingsTarget, RevenueStream, Strategy } from '../models';
import { StreamData } from '../models/stream-data';
import { Path } from '../utils';
import { BaseDAO } from './base.dao';
import { BookingsModelDAO } from './bookings-model.dao';
import { ProcessDAO } from './process.dao';
import { StaffingModelDAO } from './staffing-model.dao';

export class StreamDataDAO extends BaseDAO<StreamData> {
	public get bookingsModelId(): string {
		return this.snapshot.bookingsModel != null && this.snapshot.bookingsModel.id;
	}

	public get bookingsModel(): Observable<BookingsModelDAO> {
		return this.getSubDocumentDao((x) => x.bookingsModel, BookingsModelDAO);
	}

	public get sandboxBookingsModelId(): string {
		return this.snapshot.sandboxBookingsModel != null && this.snapshot.sandboxBookingsModel.id;
	}

	public get sandboxBookingsModel(): Observable<BookingsModelDAO> {
		return this.getSubDocumentDao((x) => x.sandboxBookingsModel, BookingsModelDAO);
	}

	public get actualBookingsModelId(): string {
		return this.snapshot.actualBookingsModel != null && this.snapshot.actualBookingsModel.id;
	}

	public get actualBookingsModel(): Observable<BookingsModelDAO> {
		return this.getSubDocumentDao((x) => x.actualBookingsModel, BookingsModelDAO);
	}

	public get staffingModelId(): string {
		return this.snapshot.staffingModel != null && this.snapshot.staffingModel.id;
	}

	public get staffingModel(): Observable<StaffingModelDAO> {
		return this.getSubDocumentDao((x) => x.staffingModel, StaffingModelDAO);
	}

	public get sandboxStaffingModelId(): string {
		return this.snapshot.sandboxStaffingModel != null && this.snapshot.sandboxStaffingModel.id;
	}

	public get sandboxStaffingModel(): Observable<StaffingModelDAO> {
		return this.getSubDocumentDao((x) => x.sandboxStaffingModel, StaffingModelDAO);
	}

	public get processId(): string {
		return this.snapshot.process != null && this.snapshot.process.id;
	}

	public get process(): Observable<ProcessDAO> {
		return this.getSubDocumentDao((x) => x.process, ProcessDAO);
	}

	// TODO: this is not good practice - the method should be elsewhere
	getBookingsTarget(isNextYear = false): Observable<BookingsTarget> {
		const { tenantId, strategyId } = Path.getIds(this.path);
		return combineLatest([
			this._afs.doc<RevenueStream>(Path.revenueStream(tenantId, this.id)).get(),
			this._afs.doc<Strategy>(Path.strategy(tenantId, strategyId)).get(),
		]).pipe(map(([rsDoc, stratDoc]) => rsDoc.data().bookingsTargets?.[stratDoc.data().year + (isNextYear ? 1 : 0)]));
	}
}
