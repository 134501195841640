import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule, SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, MatButtonToggleDefaultOptions } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import {
	MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_SELECT_CONFIG, MatSelectConfig } from '@angular/material/select';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedAuthModule } from '@app/shared/auth';
import { environment } from '@env/environment';
import { EnvironmentConfig } from '@env/environment-config';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorDialogComponent } from './components';
import { CoreModule } from './shared/core';
import { DataModule } from './shared/data';
import { GlobalErrorHandlerService } from './shared/shared/services';

@NgModule({
	declarations: [AppComponent, ErrorDialogComponent],
	imports: [
		BrowserModule,
		MatDialogModule,
		FormsModule,
		ReactiveFormsModule,
		AppRoutingModule,
		SharedAuthModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule,
		AngularFireAuthModule,
		AngularFireStorageModule,
		BrowserAnimationsModule,
		MatFormFieldModule,
		MatButtonModule,
		MatInputModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		CoreModule,
		DataModule,
	],
	providers: [
		{ provide: ErrorHandler, useClass: GlobalErrorHandlerService },
		{ provide: EnvironmentConfig, useValue: environment },
		{
			provide: FIRESTORE_SETTINGS,
			useValue: { ignoreUndefinedProperties: true, experimentalAutoDetectLongPolling: true, merge: true },
		},
		{
			provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
			useValue: { hideSingleSelectionIndicator: true } as MatButtonToggleDefaultOptions,
		},
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } as MatFormFieldDefaultOptions },
		{ provide: MAT_SELECT_CONFIG, useValue: { hideSingleSelectionIndicator: true } as MatSelectConfig },
		{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 1000 } as MatTooltipDefaultOptions },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
