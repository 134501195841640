export function unmaskNumber(value: any): number {
	if (value == null || typeof value !== 'string') {
		return value;
	}
	// remove all non-numeric characters
	const val = value.replace(/[^0-9.-]/g, '');
	const intValue = parseInt(val, 10);
	return Number.isNaN(intValue) ? null : intValue;
}

export function unmaskFloat(value: any): number {
	if (value == null || typeof value !== 'string') {
		return value;
	}
	// remove all non-numeric characters
	const val = value.replace(/[^0-9.-]/g, '');
	const floatValue = parseFloat(val);
	return Number.isNaN(floatValue) ? null : floatValue;
}
