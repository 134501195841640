export enum Quarter {
	Q1 = 'Q1',
	Q2 = 'Q2',
	Q3 = 'Q3',
	Q4 = 'Q4'
}

export const Quarters = [Quarter.Q1, Quarter.Q2, Quarter.Q3, Quarter.Q4];

export type QuarterValue = { [key in Quarter]: number };
