import { User, UserWithId } from '@app/shared/data';

export interface AutocompleteFilterSelectConfig<T, Tv> {
	display: (x: T) => string;
	value: (x: T) => Tv;
	errorMessages?: { [error: string]: string };
}

export const UserAutoCompleteConfig = {
	display: (x) => (x ? `${x.firstName} ${x.lastName}` : ''),
	value: (x) => x,
} as AutocompleteFilterSelectConfig<User, User>;

export const UserToIdAutoCompleteConfig = {
	display: (x) => (x ? `${x.firstName} ${x.lastName}` : ''),
	value: (x) => (x ? x.id : null),
} as AutocompleteFilterSelectConfig<UserWithId, string>;
