export enum IssueStatus {
	assigned = 'Solve this Quarter',
	backlog = 'Send to Backlog',
	resolved = 'Resolved',
	unreported = 'Unreported'
}

export function issueStatusSortOrder(status: IssueStatus): number {
	switch (status) {
		case IssueStatus.assigned:
			return 0;
		case IssueStatus.backlog:
			return 1;
		case IssueStatus.resolved:
			return 2;
		default:
			return 4;
	}
}
