import { AfterContentInit, Directive, ElementRef, Inject, OnChanges } from '@angular/core';

@Directive({
	selector: '[ayeqFocus]',
})
export class FocusDirective implements OnChanges, AfterContentInit {
	constructor(@Inject(ElementRef) private element: ElementRef) {}

	ngAfterContentInit() {
		if (!this.isAlreadyFocusedOnAnInput()) {
			this.element.nativeElement.focus({ preventScroll: true });
		}
	}

	public ngOnChanges() {
		if (!this.isAlreadyFocusedOnAnInput()) {
			this.element.nativeElement.focus({ preventScroll: true });
		}
	}

	isAlreadyFocusedOnAnInput() {
		const tagName = document.activeElement.tagName.toLowerCase();
		if (tagName === 'input' || tagName === 'textarea') {
			return true;
		} else {
			return false;
		}
	}
}
