import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';

export class ArrayValidators {
	// max length
	public static maxLength(max: number): ValidatorFn | any {
		return (control: AbstractControl[]) => {
			if (!(control instanceof FormArray)) return null;
			return (control.value as boolean[]).filter((x) => x).length > max ? { maxLength: true } : null;
		};
	}

	// min length
	public static minLength(min: number): ValidatorFn | any {
		return (control: AbstractControl[]) => {
			if (!(control instanceof FormArray)) return null;
			return (control.value as boolean[]).filter((x) => x).length < min ? { minLength: true } : null;
		};
	}
}
