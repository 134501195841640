import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

@Injectable()
export class PrintService {
	public constructor(private platform: Platform) {}
	public print() {
		if (this.platform.SAFARI) {
			// This may be deprecated, but it's still supported
			// https://caniuse.com/?search=execcommand
			// eslint-disable-next-line deprecation/deprecation
			document.execCommand('print', false, null);
		} else {
			window.print();
		}
	}
}
