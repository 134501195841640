export enum MetricDoc {
	BookingsQtd = 'BOOKINGS_QTD',
	BookingsYtd = 'BOOKINGS_YTD',
	Forecast = 'FORECAST',
	BookingsPlan = 'BOOKINGS_PLAN',
}

export interface BookingsToDateMetric {
	current: number;
	goal: number;
}

export interface ForecastMetric {
	[year: string]: {
		[quarter: string]: {
			[stage: string]: string;
		};
	};
}

export interface BookingsPlanMetric {
	[revenueStreamId: string]: {
		predicted: string;
		target: string;
		won: string;
	};
}
