import { Observable, ReplaySubject } from 'rxjs';
import { share } from 'rxjs/operators';

// ShareReplay will multicast the value and replay based on the buffer size but it will only release
// its internal subscription once the ref count reaches 0 (no subscribers) AND the source stream
// completes or errors. We want the stream to go cold when there are no subscribers regardless
// of whether the source stream has closed.
export function multicastReplay<T>(bufferSize: number = 0): (source: Observable<T>) => Observable<T> {
	return (source: Observable<T>) =>
		source.pipe(
			share({
				connector: () => new ReplaySubject(bufferSize),
				resetOnError: false,
				resetOnComplete: false,
				resetOnRefCountZero: false,
			})
		);
}
/* This use to be piped to

	publishReplay(bufferSize),
	refCount()

	Since the were deprecated, RxJS recommends this exact usage to be transformed into the above,
	as reference in the second example: https://rxjs.dev/deprecations/multicasting#publishreplay
*/
