import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';

const LARGE_BREAKPOINT = window.getComputedStyle(document.documentElement).getPropertyValue('--large-breakpoint');
const MIN_STICK_SIDENAV = window.getComputedStyle(document.documentElement).getPropertyValue('--min-stick-sidenav');

export enum ScreenSize {
	small = 'small',
	large = 'large',
}

@Injectable()
export class ScreenSizeService implements OnDestroy {
	public readonly screenSize = new BehaviorSubject<ScreenSize>(
		window.innerWidth >= parseInt(LARGE_BREAKPOINT, 10) ? ScreenSize.large : ScreenSize.small
	);

	public readonly canStickSidenav = new BehaviorSubject<boolean>(window.innerWidth >= parseInt(MIN_STICK_SIDENAV, 10));

	private sub = new Subscription();

	constructor(breakpointObserver: BreakpointObserver) {
		this.sub.add(
			breakpointObserver.observe(`(min-width: ${LARGE_BREAKPOINT})`).subscribe((x) => {
				this.screenSize.next(x.matches ? ScreenSize.large : ScreenSize.small);
			})
		);
		this.sub.add(
			breakpointObserver.observe(`(min-width: ${MIN_STICK_SIDENAV})`).subscribe((x) => {
				this.canStickSidenav.next(x.matches);
			})
		);
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}
}
