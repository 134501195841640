import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

@Injectable()
export class EndpointTesterService {
	public constructor(private http: HttpClient) {}
	public testHtmlEndpoint(url: string): Observable<boolean> {
		return this.http
			.get(url, {
				observe: 'response',
				responseType: 'text',
			})
			.pipe(
				map((res: HttpResponse<any>) => res.ok),
				first(),
				catchError((): Observable<boolean> => new Observable((obs: any) => obs.next(false)))
			);
	}

	public testJsonEndpoint(url: string): Observable<boolean> {
		return this.http
			.get(url, {
				observe: 'response',
			})
			.pipe(
				map((res: HttpResponse<any>) => res.ok),
				first(),
				catchError((): Observable<boolean> => new Observable((obs: any) => obs.next(false)))
			);
	}
}
