import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';
import { firstValueFrom } from 'rxjs';

import { LogService } from '@app/shared/core';

import { HistoricalBookingsModel, StreamData } from '../models';
import { Path } from '../utils';

@Injectable()
export class StreamDataRepository {
	constructor(private afs: AngularFirestore, private log: LogService) {}

	public async removeProcessFromStreams(processPath: string) {
		const { tenantId, strategyId } = Path.getIds(processPath);
		const processDoc = this.afs.doc(processPath);
		const streams = await firstValueFrom(
			this.afs
				.collection<StreamData>(Path.streamDataCollection(tenantId, strategyId), (x) =>
					x.where('process', '==', processDoc.ref)
				)
				.get()
		);
		await Promise.all(streams.docs.map((s) => this.deleteProcessAndBookingsModel(s)));
	}

	private async deleteProcessAndBookingsModel(
		streamData: firebase.firestore.QueryDocumentSnapshot<StreamData>
	): Promise<void> {
		const update = { process: firebase.firestore.FieldValue.delete() } as StreamData;
		this.deleteBookingsModel(streamData, update);
	}

	public async changeProcess(streamDataRef: DocumentReference<StreamData>, processId: string): Promise<void> {
		const streamData = await streamDataRef.get();
		if (!streamData.exists) {
			return;
		}
		const { tenantId, strategyId } = Path.getIds(streamDataRef.path);
		const processRef = this.afs.doc(Path.process(tenantId, strategyId, processId)).ref;

		// remove any bookings model associated with the old process
		await this.deleteBookingsModel(streamData, {
			process: processRef || firebase.firestore.FieldValue.delete(),
		} as StreamData);
	}

	public async deleteBookingsModel(
		streamDataDoc: firebase.firestore.DocumentSnapshot<StreamData>,
		initialUpdate?: StreamData
	): Promise<void> {
		const streamData = streamDataDoc.data();
		const update = initialUpdate ?? ({} as StreamData);
		if (streamData.bookingsModel != null) {
			update.bookingsModel = firebase.firestore.FieldValue.delete() as any;
			update.historicalBookingsModels = firebase.firestore.FieldValue.arrayUnion({
				archivedOn: firebase.firestore.Timestamp.now(),
				bookingsModel: streamData.bookingsModel,
				process: streamData.process,
			} as HistoricalBookingsModel) as any;

			const bookingsModelData = await streamData.bookingsModel.get();
			this.log.audit('Bookings model archived', {
				revenueStream: streamDataDoc.id,
				bookingsModelData: bookingsModelData.data(),
			});
		}
		if (streamData.sandboxBookingsModel != null) {
			await streamData.sandboxBookingsModel.delete();
			update.sandboxBookingsModel = firebase.firestore.FieldValue.delete() as any;

			const bookingsModelData = await streamData.sandboxBookingsModel.get();
			this.log.audit('Sandbox bookings model deleted', {
				revenueStream: streamDataDoc.id,
				sandboxBookingsModelData: bookingsModelData.data(),
			});
		}
		if (Object.keys(update).length) {
			await streamDataDoc.ref.update(update);
		}
	}
}
