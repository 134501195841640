@if (vm$ | async; as vm) {
	@if (!vm.gtmComplete) {
		<div class="my-3 text-end">
			<button
				mat-raised-button
				[color]="vm.stepComplete ? 'secondary' : 'primary'"
				[class.ayeq-green]="isModel"
				(click)="mark()"
			>
				Mark {{ vm.stepComplete ? 'Incomplete' : 'Complete' }}
			</button>
		</div>
	}
}
