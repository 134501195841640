import { Observable } from 'rxjs';
import { filter, map, pairwise, startWith } from 'rxjs/operators';

/**
 * Deep equivalency test of two values.
 * If they are value types then do a strict comparrison.
 * If they are arrays then iterate their values and compare them by index.
 * If they are sets then ensure that both sets contain the same values.
 * Can't support objects in sets as there is no way to know which values
 * should be compared between the sets.
 * If they are maps then iterate their keys and compare them by key.
 * If they are objects then iterate their properties and compare them by key.
 * @param a
 * @param b
 */
function equivalent(a, b): boolean {
	if (a === b) {
		return true;
	}
	if (Array.isArray(a) && Array.isArray(b)) {
		if (a.length !== b.length) {
			return false;
		}
		return a.every((v, i) => equivalent(b[i], v));
	}
	if (a instanceof Set && b instanceof Set) {
		if (a.size !== b.size) {
			return false;
		}
		return Array.from(a).every((v) => b.has(v));
	}
	if (a instanceof Map && b instanceof Map) {
		if (a.size !== b.size) {
			return false;
		}
		return Array.from(a.keys()).every((k) => equivalent(b[k], a[k]));
	} else if (a instanceof Object && b instanceof Object) {
		if (Object.keys(a).length !== Object.keys(b).length) {
			return false;
		}
		return Object.keys(a).every((k) => equivalent(b[k], a[k]));
	}
	return false;
}

/**
 * Deep distinctUntilChanged operator based on equivalency.
 */
export function distinctEquivalency<T>(): (source: Observable<T>) => Observable<T> {
	return (source: Observable<T>) =>
		source.pipe(
			startWith(<T>null),
			pairwise(),
			filter(([a, b]) => a == null || !equivalent(a, b)),
			map(([, b]) => b)
		);
}
