import { DocumentReference } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';

import { TrackingStatus } from '../enums';
import { Identity } from './identity';

export interface Imperative extends Identity {
	createdOn: firebase.firestore.Timestamp;
	description: string;
	initiative: DocumentReference;
	name: string;
	owner: DocumentReference;
	successCriteria: string;
	statusUpdatedOn: firebase.firestore.Timestamp;
	status: TrackingStatus;
	notes: string;
}
