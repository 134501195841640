const toNumber = /[^0-9.-]+/g;

export function percentTransform(value: any, max: number = 100, min: number = 0): number {
	let numValue = Number(`${value}`.replace(toNumber, ''));
	if (numValue > max) {
		numValue = max;
	}
	if (numValue < min) {
		numValue = min;
	}
	return numValue;
}
