import { Component, Input } from '@angular/core';

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
	Issue, IssueRepository, IssueStatus, issueStatusSortOrder, prioritySortOrder, User, UserRepository,
} from '@app/shared/data';
import { multicastReplay, TermKey } from '@app/shared/shared';
import { faLongArrowUp } from '@fortawesome/pro-solid-svg-icons';

import { TenantService } from '../../services/tenant.service';

export interface IssueWithUser extends Issue {
	id: string;
	usersName: string;
}

interface SortableIssueWithUser extends IssueWithUser {
	statusNumber: number;
	priorityNumber: number;
}

function sortableIssueComparator(a: SortableIssueWithUser, b: SortableIssueWithUser): number {
	if (a.statusNumber !== b.statusNumber) {
		return a.statusNumber - b.statusNumber;
	} else if (a.priorityNumber !== b.priorityNumber) {
		return a.priorityNumber - b.priorityNumber;
	} else {
		return a.createdOn.toMillis() - b.createdOn.toMillis();
	}
}

@Component({
	selector: 'ayeq-issue-backlog',
	templateUrl: './issue-backlog.component.html',
})
export class IssueBacklogComponent {
	longArrowUp = faLongArrowUp;
	public TermKey = TermKey;

	private _query: string;
	@Input() set query(value: string) {
		this._query = value;
		this.query$.next(value);
	}

	get query(): string {
		return this._query;
	}

	query$ = new BehaviorSubject('');

	issues$: Observable<SortableIssueWithUser[]>;

	constructor(
		private issueRepository: IssueRepository,
		userRepository: UserRepository
	) {
		const issues$ = this.issueRepository.getIssuesForStatuses(TenantService.tenantId, [IssueStatus.backlog]).pipe(
			map((issues) =>
				issues
					.filter((issue) => issue.resolution && issue.resolution)
					.map((issue) => ({
						...issue,
						statusNumber: issueStatusSortOrder(issue.resolution),
						priorityNumber: prioritySortOrder(issue.priority),
					}))
					.sort(sortableIssueComparator)
			)
		) as Observable<SortableIssueWithUser[]>;
		this.issues$ = combineLatest([issues$, this.query$]).pipe(
			switchMap(async ([issues, query]) => {
				const users = await userRepository.getUsersByTenantId(TenantService.tenantId);
				const userMap = new Map<string, User>();
				users.forEach((u) => userMap.set(u.id, u));
				return issues
					.map((i) => ({ ...i, usersName: this.nameOfUser(userMap.get(i.createdBy.id)) }))
					.filter((i) => this.matchesQuery(query, i));
			}),
			multicastReplay(1)
		);
	}

	private nameOfUser(user: User): string {
		return user ? `${user.firstName} ${user.lastName}` : 'Unknown';
	}

	private matchesQuery(query: string, issue: SortableIssueWithUser): boolean {
		if (!query) {
			return true;
		}
		const q = query.toLowerCase();
		return issue.name.toLowerCase().includes(q) || issue.usersName.toLowerCase().includes(q);
	}
}
