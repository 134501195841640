import { Quarter, QuarterValue } from '../enums';

export interface BookingsTarget {
	amount: number;
	yoyGrowth: number;
	quarterMode: BookingsTargetQuarterMode;
	seasonality: QuarterValue;
}

export enum BookingsTargetQuarterMode {
	Amount = 'AMOUNT',
	Percent = 'PERCENT',
}

export function generateDefaultBookingsTarget(): BookingsTarget {
	return {
		amount: 0,
		yoyGrowth: 0,
		quarterMode: BookingsTargetQuarterMode.Percent,
		seasonality: { Q1: 25, Q2: 25, Q3: 25, Q4: 25 },
	};
}

export function generateNextBookingsTarget(bookingsTarget: BookingsTarget): BookingsTarget;
export function generateNextBookingsTarget(bookingsTarget?: BookingsTarget): BookingsTarget | undefined {
	if (!bookingsTarget) return undefined;
	return {
		amount: Math.round(bookingsTarget.amount * (1 + (bookingsTarget.yoyGrowth ?? 0) / 100)),
		yoyGrowth: bookingsTarget.yoyGrowth,
		quarterMode: BookingsTargetQuarterMode.Percent,
		seasonality:
			bookingsTarget.quarterMode === BookingsTargetQuarterMode.Percent
				? bookingsTarget.seasonality
				: convertSeasonality(bookingsTarget, BookingsTargetQuarterMode.Percent),
	};
}

export function convertSeasonality(bt: BookingsTarget, toMode?: BookingsTargetQuarterMode): QuarterValue {
	if (toMode && toMode === bt.quarterMode) return bt.seasonality;
	if (!bt.seasonality || Object.values(bt.seasonality).every((x) => x === 0)) {
		return { Q1: 25, Q2: 25, Q3: 25, Q4: 25 };
	}
	if (bt.quarterMode === BookingsTargetQuarterMode.Amount) {
		return largestRemainderRound(bt.seasonality, 100, 2);
	} else {
		return largestRemainderRound(bt.seasonality, bt.amount, 0);
	}
}

function largestRemainderRound(qv: QuarterValue, desiredTotal: number, numDecimals = 0): QuarterValue {
	const total = desiredTotal * 10 ** numDecimals;
	const upperSum = Object.values(qv).reduce((a, b) => a + b);
	const sortedArray = (Object.entries(qv) as [Quarter, number][])
		.map(([quarter, v]) => {
			const value = (v / upperSum) * total;
			const floor = Math.floor(value);
			return { floor, remainder: value - floor, quarter };
		})
		.sort((a, b) => b.remainder - a.remainder);

	const lowerSum = sortedArray.reduce((a, b) => a + b.floor, 0);
	for (let i = 0; i < total - lowerSum; i++) {
		sortedArray[i].floor++;
	}
	return sortedArray.reduce(
		(acc, cur) => ({ ...acc, [cur.quarter]: cur.floor / 10 ** numDecimals }),
		{} as QuarterValue
	);
}
