import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[ayeqOnOrIn]',
	exportAs: 'ayeqOnOrIn',
})
export class OnOrInDirective {
	private isIn = false;
	private isFocused = false;
	get active() {
		return this.isIn || this.isFocused;
	}

	@HostListener('mouseenter') onMouseEnter() {
		this.isIn = true;
	}

	@HostListener('mouseleave') onMouseLeave() {
		this.isIn = false;
	}

	@HostListener('focusin') onFocusIn() {
		this.isFocused = true;
	}

	@HostListener('focusout') onFocusOut() {
		this.isFocused = false;
	}
}
