import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { ConsoleAppender, StackdriverAppender } from './appenders';
import { LogMessage } from './log-message.model';

/**
 * Core log service that holds the pipeline for log messages.
 * Must be a singleton.
 * @export
 * @class LogCoreService
 */
@Injectable()
export class LogCoreService {
	private messagesStream: Subject<LogMessage>;

	constructor(consoleAppender: ConsoleAppender, stackdriverAppender: StackdriverAppender) {
		this.messagesStream = new Subject<LogMessage>();
		// configure the log appenders
		this.messages.subscribe((logMessage) => {
			consoleAppender.write(logMessage);
			if (environment.production) {
				stackdriverAppender.write(logMessage);
			}
		});
	}

	/**
	 * Observable stream of log messages.
	 * Meant for appenders to subscribe on.
	 * @readonly
	 * @type {Observable<LogMessage>}
	 * @memberof LogService
	 */
	public get messages(): Observable<LogMessage> {
		return this.messagesStream.asObservable();
	}

	/**
	 * Log a message.
	 * @param {LogMessage} message
	 * @memberof LogCoreService
	 */
	public log(message: LogMessage): void {
		this.messagesStream.next(message);
	}
}
