import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ActionItemDAO } from '../dao/action-item.dao';
import { DaoFactory } from '../dao/base.dao';
import { ActionItemStatus } from '../enums';
import { Path } from '../utils/path';

type FirestoreWhere = (
	query: firebase.firestore.Query<firebase.firestore.DocumentData>
) => firebase.firestore.Query<firebase.firestore.DocumentData>;

@Injectable()
export class ActionItemRepository {
	constructor(private afs: AngularFirestore, private daoFactory: DaoFactory) {}

	public getActionItemsForUserAndDate(
		tenantId: string,
		userId: string,
		createdBefore: firebase.firestore.Timestamp
	): Observable<ActionItemDAO[]> {
		const user = this.afs.doc(Path.user(userId));
		return this.afs
			.collection(Path.actionItems(tenantId), (query) => {
				let q = query
					.where('owner', '==', user.ref)
					.where('status', 'in', [ActionItemStatus.inProgress, ActionItemStatus.notStarted, '']);
				if (createdBefore) {
					q = q.where('createdOn', '<', createdBefore);
				}
				return q;
			})
			.get()
			.pipe(
				switchMap((actionItems) => {
					if (actionItems.empty) return of([]);
					return combineLatest(actionItems.docs.map((ai) => this.daoFactory.build(ActionItemDAO, ai.ref.path)));
				})
			);
	}

	public getImperativeActionItemsBetweenDates(
		tenantId: string,
		imperativePath: string,
		startDate?: firebase.firestore.Timestamp,
		endDate?: firebase.firestore.Timestamp
	) {
		return this._getActionItemsBetweenDates(tenantId, startDate, endDate, (q) => {
			let query = q;
			if (imperativePath != null) {
				const imperative = this.afs.doc(imperativePath);
				query = query.where('imperative', '==', imperative.ref);
			}
			return query;
		});
	}

	public getIssueActionItemsBetweenDates(
		tenantId: string,
		startDate?: firebase.firestore.Timestamp,
		endDate?: firebase.firestore.Timestamp
	) {
		return this._getActionItemsBetweenDates(tenantId, startDate, endDate, (query) => query.orderBy('issue'));
	}

	public getActionItemsBetweenDates(
		tenantId: string,
		startDate?: firebase.firestore.Timestamp,
		endDate?: firebase.firestore.Timestamp
	): Observable<ActionItemDAO[]> {
		return this._getActionItemsBetweenDates(tenantId, startDate, endDate);
	}

	private _getActionItemsBetweenDates(
		tenantId: string,
		startDate?: firebase.firestore.Timestamp,
		endDate?: firebase.firestore.Timestamp,
		where?: FirestoreWhere
	): Observable<ActionItemDAO[]> {
		return combineLatest(
			// action items that were put in a final state
			[
				this.afs
					.collection(Path.actionItems(tenantId), (ai) => {
						let query: firebase.firestore.Query<firebase.firestore.DocumentData> = ai;
						query = query.where('status', 'in', [ActionItemStatus.abandoned, ActionItemStatus.completed, '']);
						if (startDate != null) {
							query = query.where('statusUpdatedOn', '>', startDate);
						}
						if (endDate != null) {
							query = query.where('statusUpdatedOn', '<', endDate);
						}
						if (where) {
							query = where(query);
						}
						return query;
					})
					.get(),
				this.afs
					.collection(Path.actionItems(tenantId), (ai) => {
						let query = ai.where('status', 'in', [ActionItemStatus.inProgress, ActionItemStatus.notStarted]);
						if (endDate != null) {
							query = query.where('createdOn', '<', endDate);
						}
						return query;
					})
					.get(),
			]
		).pipe(switchMap(this.dedupeQueryResults.bind(this)));
	}

	dedupeQueryResults(actionItemQueries: firebase.firestore.QuerySnapshot[]): Observable<ActionItemDAO[]> {
		const actionItems = [].concat(...actionItemQueries.map((ai) => ai.docs));
		const uniqActionItems = new Map();
		// Dedupe
		for (const actionItem of actionItems) {
			uniqActionItems.set(actionItem.ref.path, actionItem);
		}
		return actionItems.length > 0
			? combineLatest([...uniqActionItems.values()].map((ai) => this.daoFactory.build(ActionItemDAO, ai.ref.path)))
			: of([]);
	}
}
