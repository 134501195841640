import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { LogService } from '@app/shared/core';
import { PersistenceService } from '@app/shared/data';

import { InputComponent } from '../input.component';

@Component({
	selector: 'ayeq-radio-options',
	template: `
		<mat-radio-group [formControl]="control" [ngClass]="fieldClass" color="primary">
			<mat-radio-button *ngFor="let option of options" [value]="option.key">
				<span [ngClass]="valueClass">{{ option.value }}</span>
			</mat-radio-button>
			<mat-radio-button *ngIf="deleteLabel" [value]="null">{{ deleteLabel }}</mat-radio-button>
		</mat-radio-group>
	`,
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioOptionsComponent<TValue> extends InputComponent<any, TValue, string> {
	@Input() options: KeyValue<TValue, string>[];
	@Input() fieldClass: string | string[] | Set<string> | { [klass: string]: any } | null | undefined;
	@Input() valueClass: string | string[] | Set<string> | { [klass: string]: any } | null | undefined;
	@Input() deleteLabel?: string;

	constructor(log: LogService, persistenceService: PersistenceService) {
		super(log, persistenceService, 'change');
	}

	protected unmask(x: any): TValue {
		return x;
	}
}
