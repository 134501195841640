import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog } from '@angular/material/dialog';

import { ErrorDialogComponent } from '@app/components';
import { LogService } from '@app/shared/core';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
	constructor(
		private injector: Injector,
		private ngzone: NgZone,
		private afa: AngularFireAuth
	) {}

	async handleError(error: Error) {
		const logService = this.injector.get(LogService);
		const dialog = this.injector.get(MatDialog);
		if (
			error.name?.toLowerCase().includes('firebaseerror') &&
			dialog.openDialogs.length === 0 &&
			(await this.afa.currentUser) != null
		) {
			this.ngzone.run(() => {
				dialog.open(ErrorDialogComponent, {
					width: '450px',
					disableClose: true,
				});
			});
		}
		if (!error.message?.includes('auth/')) {
			logService.error(error.message, {}, error);
		}
	}
}
