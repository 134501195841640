import { Injectable } from '@angular/core';

import firebase from 'firebase/compat/app';
import { firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';

import { ProgramDAO } from '../dao';

@Injectable()
export class ProgramRepository {
	public async deletePrograms(programs: ProgramDAO[]): Promise<void[]> {
		return Promise.all(programs.map((program) => this.deleteProgram(program)));
	}

	public async deleteProgram(program: ProgramDAO): Promise<void> {
		const batch = firebase.firestore().batch();
		const campaigns = await firstValueFrom(program.campaigns.pipe(first()));
		campaigns.forEach((campaign) => {
			batch.delete(campaign.ref);
		});
		batch.delete(program.ref);
		await batch.commit();
	}
}
