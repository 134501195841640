import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { firstValueFrom } from 'rxjs';

import { LocalStorageKeys } from '@app/shared/shared/constants';

import { WebStorage } from '../../webStorage';
import { LogLevel } from '../log-level.model';
import { LogMessage } from '../log-message.model';

const Severities = {
	Debug: 'DEBUG',
	Info: 'INFO',
	Warn: 'WARNING',
	Error: 'ERROR',
	Analytics: 'ANALYTICS',
};

@Injectable()
export class StackdriverAppender {
	constructor(private functions: AngularFireFunctions, private afa: AngularFireAuth) {}

	/**
	 * Write a log message to stackdriver.
	 * @param {LogMessage} logMessage
	 * @memberof StackdriverAppender
	 */
	public write(logMessage: LogMessage): void {
		const logLevel = this.getLevel(logMessage.level);
		if ([Severities.Info, Severities.Warn, Severities.Error].includes(logLevel)) {
			this.logMessage(logMessage, logLevel);
		}
	}

	private async logMessage(logMessage: LogMessage, logLevel: string): Promise<void> {
		await firstValueFrom(
			this.functions.httpsCallable('logMessage')({
				logMessage: {
					...logMessage,
					location: window.location.toString(),
					tenantId: WebStorage.getItem(LocalStorageKeys.TenantId),
					strategy: WebStorage.getItem(LocalStorageKeys.SelectedStrategyYear),
					userId: (await this.afa.currentUser)?.uid,
				},
				logLevel,
			})
		);
	}

	/**
	 * Get the method to use for logging the message based on its level.
	 * @private
	 * @param {LogLevel} level
	 * @returns {Severities}
	 * @memberof StackdriverAppender
	 */
	// eslint-disable-next-line consistent-return
	private getLevel(level: LogLevel): string {
		switch (level) {
			case LogLevel.Off:
				return null;
			case LogLevel.Trace:
			case LogLevel.Debug:
				return Severities.Debug;
			case LogLevel.Info:
			case LogLevel.Audit:
				return Severities.Info;
			case LogLevel.Warn:
				return Severities.Warn;
			case LogLevel.Error:
			case LogLevel.Fatal:
			case LogLevel.All:
				return Severities.Error;
		}
	}
}
