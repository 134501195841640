import { Observable } from 'rxjs';

import { Campaign } from '../models';
import { Path } from '../utils';
import { BaseDAO } from './base.dao';

export class CampaignDAO extends BaseDAO<Campaign> {
	private _children: Observable<CampaignDAO[]>;

	get children(): Observable<CampaignDAO[]> {
		if (this._children == null) {
			const { tenantId, strategyId } = Path.getIds(this.path);
			const path = Path.campaigns(tenantId, strategyId);
			// TODO: ordering
			this._children = this.initCollection(CampaignDAO, path, (ref) => ref.where('parent', '==', this.ref));
		}
		return this._children;
	}
}
