export interface Segment {
	name: string;
	type: SegmentType;
	grouping?: SegmentGrouping;
	order: number;
}

export enum SegmentType {
	TarketMarket = 'TARGET_MARKET',
	Offering = 'OFFERING',
	Marketing = 'MARKETING',
}

export enum SegmentGrouping {
	Firmographics = 'FIRMOGRAPHICS',
	Personas = 'PERSONAS',
}
