export const LOG_ZONE = 'LOG_ZONE';

export enum LogZone {
	GLOBAL = 'Global',
	FORM = 'Form',
	PERSIST = 'Persist',
	PROGRAM = 'Program',
	EXECUTION = 'Execution',
	COLLABORATION_MEETING = 'Collaboration Meeting'
}
