import { Role } from '../enums';

export interface User {
	firstName: string;
	lastName: string;
	email: string;
	role: Role;
	tenantId: string;
	isDeleted: boolean;
}

export type UserWithId = User & {
	id: string;
};
