import { Observable } from 'rxjs';

import { Strategy } from '../models';
import { FsCollection } from '../utils';
import { BaseDAO } from './base.dao';
import { BookingsModelDAO } from './bookings-model.dao';
import { CampaignDAO } from './campaign.dao';
import { CustomScorecardDAO } from './custom-scorecard.dao';
import { InitiativeDAO } from './initiative.dao';
import { ProcessDAO } from './process.dao';
import { QuarterlyPlanDAO } from './quarterly-plan.dao';
import { StreamDataDAO } from './stream-data.dao';

export class StrategyDAO extends BaseDAO<Strategy> {
	private _bookingsModels: Observable<BookingsModelDAO[]>;
	private _streamData: Observable<StreamDataDAO[]>;
	private _processes: Observable<ProcessDAO[]>;
	private _initiatives: Observable<InitiativeDAO[]>;
	private _quarterlyPlans: Observable<QuarterlyPlanDAO[]>;
	private _customScorecards: Observable<CustomScorecardDAO[]>;
	private _topLevelCampaigns: Observable<CampaignDAO[]>;

	get bookingsModels(): Observable<BookingsModelDAO[]> {
		if (this._bookingsModels == null) {
			const path = `${this.path}/${FsCollection.BookingsModels}`;
			this._bookingsModels = this.initCollection(BookingsModelDAO, path);
		}
		return this._bookingsModels;
	}

	get streamData(): Observable<StreamDataDAO[]> {
		if (this._streamData == null) {
			const path = `${this.path}/${FsCollection.StreamData}`;
			this._streamData = this.initCollection(StreamDataDAO, path);
		}
		return this._streamData;
	}

	get processes(): Observable<ProcessDAO[]> {
		if (this._processes == null) {
			const path = `${this.path}/${FsCollection.Processes}`;
			this._processes = this.initCollection(ProcessDAO, path);
		}
		return this._processes;
	}

	get initiatives(): Observable<InitiativeDAO[]> {
		if (this._initiatives == null) {
			const path = `${this.path}/${FsCollection.Initiatives}`;
			this._initiatives = this.initCollection(InitiativeDAO, path);
		}
		return this._initiatives;
	}

	get quarterlyPlans(): Observable<QuarterlyPlanDAO[]> {
		if (this._quarterlyPlans == null) {
			const path = `${this.path}/${FsCollection.QuarterlyPlans}`;
			this._quarterlyPlans = this.initCollection(QuarterlyPlanDAO, path);
		}
		return this._quarterlyPlans;
	}

	get customScorecards(): Observable<CustomScorecardDAO[]> {
		if (this._customScorecards == null) {
			const path = `${this.path}/${FsCollection.CustomScorecards}`;
			this._customScorecards = this.initCollection(CustomScorecardDAO, path);
		}
		return this._customScorecards;
	}

	get topLevelCampaigns(): Observable<CampaignDAO[]> {
		if (this._topLevelCampaigns == null) {
			const path = `${this.path}/${FsCollection.Campaigns}`;
			this._topLevelCampaigns = this.initCollection(CampaignDAO, path, (ref) =>
				ref.where('parent', '==', null).orderBy('order')
			);
		}
		return this._topLevelCampaigns;
	}
}
