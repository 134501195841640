import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Quarter } from '../enums';
import { Imperative } from '../models';
import { Path } from '../utils/path';
import { BaseRepository } from './base-repository.service';

@Injectable()
export class ImperativeRepository extends BaseRepository<Imperative> {
	constructor(private afs: AngularFirestore) {
		super();
	}

	public getImperatives(tenantId: string, strategyId: string, quarterlyPlanId: Quarter): Observable<Imperative[]> {
		const path = Path.imperatives(tenantId, strategyId, quarterlyPlanId);
		return this.afs
			.collection<Imperative>(path)
			.snapshotChanges()
			.pipe(map((x) => this.mapListSnapshot(x)));
	}

	public getImperativesForUser(
		tenantId: string,
		strategyId: string,
		quarterlyPlanId: Quarter,
		userId: string
	): Observable<Imperative[]> {
		const path = Path.imperatives(tenantId, strategyId, quarterlyPlanId);
		const userPath = Path.user(userId);
		const userRef = this.afs.doc(userPath).ref;
		return this.afs
			.collection<Imperative>(path, (ref) => ref.where('owner', '==', userRef))
			.snapshotChanges()
			.pipe(map((x) => this.mapListSnapshot(x)));
	}
}
