import { Observable } from 'rxjs';

import { Program } from '../models';
import { Path } from '../utils';
import { BaseDAO } from './base.dao';
import { CampaignDAO } from './campaign.dao';

export class ProgramDAO extends BaseDAO<Program> {
	private _campaigns: Observable<CampaignDAO[]>;

	public get campaigns(): Observable<CampaignDAO[]> {
		if (this._campaigns == null) {
			const { tenantId, strategyId, revenueStreamId, programId } = Path.getIds(this.path);
			const path = Path.campaigns(tenantId, strategyId, revenueStreamId, programId);
			this._campaigns = this.initCollection(CampaignDAO, path);
		}
		return this._campaigns;
	}
}
