import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';

import { firstValueFrom, Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

import { AuthService } from '@app/shared/shared/services/auth.service';

import { DaoFactory, UserDAO } from '../dao';
import { User, UserWithId } from '../models';
import { Path } from '../utils';

@Injectable()
export class UserRepository {
	currentUser: Observable<UserDAO>;

	constructor(private afs: AngularFirestore, private auth: AuthService, daoFactory: DaoFactory) {
		this.currentUser = this.auth.currentUser().pipe(
			switchMap((user) => daoFactory.build(UserDAO, Path.user(user.id))),
			shareReplay(1)
		);
	}

	getUserRefById(id: string): DocumentReference {
		return this.afs.doc<User>(Path.user(id)).ref;
	}

	async getUsersByTenantId(tenantId: string): Promise<UserWithId[]> {
		// ideally this would stream but it has an odd bug where it only pulls the current user sometimes
		const x = await firstValueFrom(
			this.afs.collection<User>(Path.users(), (user) => user.where('tenantId', '==', tenantId)).get()
		);
		return x.docs.map((y) => ({ id: y.id, ...y.data() }));
	}

	async getActiveUsersByTenantId(tenantId: string): Promise<UserWithId[]> {
		const users = await this.getUsersByTenantId(tenantId);
		return users.filter((z) => !z.isDeleted);
	}
}
