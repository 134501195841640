import { DocumentReference } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';

export interface StreamData {
	marketingPlanNotes: string;
	process: DocumentReference;

	bookingsModel: DocumentReference;
	sandboxBookingsModel: DocumentReference;
	historicalBookingsModels: HistoricalBookingsModel[];
	actualBookingsModel: DocumentReference;

	staffingModel: DocumentReference;
	sandboxStaffingModel: DocumentReference;
}

export interface HistoricalBookingsModel {
	archivedOn: firebase.firestore.Timestamp;
	process: DocumentReference;
	bookingsModel: DocumentReference;
}
