import firebase from 'firebase/compat/app';

import { CrmProvider } from '../enums/crm-provider.enum';
import { MaProvider } from '../enums/ma-provider.enum';

export interface Tenant {
	name: string;
	terms?: Terms;
	configKey?: string;
	crmProvider?: CrmProvider;
	maProvider?: MaProvider;
	biRefresh?: { [key in RefreshType]: BiRefreshProperties };
	enabledModules: AyeqModule[];
}

export interface BiRefreshProperties {
	dataFetchTopic: string;
	dataFetchFunctionName: string;
	qlikTriggeredTaskId: string;
	qlikWatchCompletionTaskId: string;
	lastInitiatedOn?: firebase.firestore.Timestamp;
	lastCompletedOn?: firebase.firestore.Timestamp;
}

export interface Terms {
	[key: string]: Term;
}

export interface Term {
	singular: string;
	plural: string;
}

export type RefreshType = 'crm' | 'ma';

export enum AyeqModule {
	Plan = 'PLAN',
	Model = 'MODEL',
	Manage = 'MANAGE',
	Optimize = 'OPTIMIZE',
}
