import { Component, Input } from '@angular/core';

import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

export interface IconProperties {
	icon: IconDefinition;
	transform?: string;
}
@Component({
	selector: 'ayeq-summary-card',
	templateUrl: './summary-card.component.html',
	styleUrls: ['./summary-card.component.scss'],
})
export class SummaryCardComponent {
	@Input() iconProps: IconProperties;
	@Input() title: string;
}
