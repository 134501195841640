export enum Priority {
	low = 'Low',
	medium = 'Medium',
	high = 'High',
	unreported = 'Unreported'
}

export const DEFAULT_PRIORITY = Priority.medium;

export function prioritySortOrder(priority: Priority): number {
	switch (priority) {
		case Priority.high:
			return 0;
		case Priority.medium:
			return 1;
		case Priority.low:
			return 2;
		default:
			return 3;
	}
}
