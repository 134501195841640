import { DocumentReference } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';

import { ActionItemStatus } from '../enums';
import { Identity } from './identity';

export interface ActionItemStatusUpdate {
	status: string;
}

export interface ActionItem extends Identity {
	owner: DocumentReference;
	issue?: DocumentReference;
	imperative?: DocumentReference;
	name: string;
	description: string;
	dueDate: firebase.firestore.Timestamp;
	createdOn: firebase.firestore.Timestamp;
	createdBy: DocumentReference;
	status: ActionItemStatus;
	statusUpdatedOn?: firebase.firestore.Timestamp;
}
