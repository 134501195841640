import { Component, Input } from '@angular/core';

import { Scorecard, TrackingStatus } from '@app/shared/data';

import { CssColor } from '../../constants/css';
import { TermKey } from '../../pipes';

export interface KpiBox extends Scorecard {
	isInitiative: boolean;
}

@Component({
	selector: 'ayeq-kpi-box',
	templateUrl: './kpi-box.component.html',
	styleUrls: ['./kpi-box.component.scss'],
})
export class KpiBoxComponent {
	TermKey = TermKey;

	@Input() set kpi(kpi: KpiBox) {
		this.isInitiative = kpi.isInitiative;
		this.title = kpi.name;
		this.percentText = kpi.performance ? kpi.performance : 'Not Reported';
		this.lightColor = this.getBgColor(kpi.status);
		this.darkColor = this.getBarColor(kpi.status);
		this.percent = kpi.progress || 0;
		this.status = this.getStatusText(kpi.status);
		this.subText = kpi.kpiStatement;
		this.annotation = kpi.annotation;
	}

	@Input() showAnnotations: boolean;

	isInitiative: boolean;
	title: string;
	percentText: string;
	lightColor: string;
	darkColor: string;
	percent: number;
	status: string;
	subText: string;
	subsubText: string;
	annotation: string;

	getStatusText(status: TrackingStatus): string {
		switch (status) {
			case TrackingStatus.completed:
				return 'Completed';
			case TrackingStatus.onTrack:
				return 'On Track';
			case TrackingStatus.atRisk:
				return 'At Risk';
			case TrackingStatus.offTrack:
				return 'Off Track';
			default:
				return 'Not Reported';
		}
	}

	getBgColor(status: TrackingStatus): string {
		switch (status) {
			case TrackingStatus.completed:
				return '#88cbbe';
			case TrackingStatus.onTrack:
				return CssColor.onTrack;
			case TrackingStatus.atRisk:
				return CssColor.atRisk;
			case TrackingStatus.offTrack:
				return CssColor.offTrack;
			default:
				return '#949494';
		}
	}

	getBarColor(status: TrackingStatus): string {
		switch (status) {
			case TrackingStatus.completed:
				return '#55988B';
			case TrackingStatus.onTrack:
				return '#2f685a';
			case TrackingStatus.atRisk:
				return '#a29f12';
			case TrackingStatus.offTrack:
				return '#c7692a';
			default:
				return '#616161';
		}
	}
}
