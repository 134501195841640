import { Component, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { combineLatest, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { filter, map, startWith, withLatestFrom } from 'rxjs/operators';

import { LogService } from '@app/shared/core';

import { AutocompleteFilterSelectConfig } from './autocomplete-filter-select-config.model';

export class ParentErrorStateMatcher implements ErrorStateMatcher {
	constructor(private parentControl: NgControl) {}
	isErrorState(): boolean {
		return this.showInvalid;
	}

	public get invalid(): boolean {
		return this.parentControl ? this.parentControl.invalid : false;
	}

	public get showInvalid(): boolean {
		if (!this.parentControl) {
			return false;
		}

		const { touched } = this.parentControl;
		return this.invalid ? touched : false;
	}
}

@Component({
	selector: 'ayeq-autocomplete-filter-select',
	templateUrl: './autocomplete-filter-select.component.html',
	styleUrls: ['./autocomplete-filter-select.component.scss'],
})
export class AutocompleteFilterSelectComponent<T> implements OnInit, OnDestroy, ControlValueAccessor {
	private _options$ = new ReplaySubject<T[]>(1);
	public readonly onSelect = new Subject<T>();
	public readonly childControl = new FormControl(null);
	public filteredOptions: Observable<any[]>;

	private onSelectSub: Subscription;
	private valueChanges: Subscription;

	// Handle initial value if it exists before filtered options is initialized
	private valueChangesReplaySubject = new ReplaySubject<T>(1);
	parentErrorStateMatcher: ParentErrorStateMatcher;

	@Input() required = false;
	@Input() placeholder = '';
	@Input() maxlength = '';
	@Input() set disabled(val: boolean) {
		if (val) {
			this.childControl.disable();
		} else {
			this.childControl.enable();
		}
	}

	// Allow an event to emit if enter is clicked with an unmatched
	// This only allows a string to be emitted
	@Input() allowUnmatched = false;

	_config: AutocompleteFilterSelectConfig<T, any> = {
		display: (x: T) => x?.toString() ?? '',
		value: (x: T) => x,
	};

	/**
	 * With the config being initialized after component init, the writeValue has already been called.
	 * Unfortunately, this value needs to be mapped correctly for the input.
	 * Which is why we run the value back through the config once it's been made available.
	 */
	@Input() set config(config) {
		this._config = config;
		this.onChange(this.config.value(this.value));
	}

	get config() {
		return this._config;
	}

	@Input()
	public set options(options: any[]) {
		this._options$.next(options);
	}

	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('value') _value: T;

	onTouched: any = () => {};
	onChange: any = () => {};

	get value() {
		return this._value;
	}

	set value(val: T) {
		this._value = val;
		this.childControl.setValue(val);
		this.onChange(val ? this.config.value(val) : null);
		this.onTouched();
	}

	constructor(
		@Self() @Optional() public parentControl: NgControl,
		private log: LogService
	) {
		if (this.parentControl) {
			this.parentControl.valueAccessor = this;
		}
		this.parentErrorStateMatcher = new ParentErrorStateMatcher(this.parentControl);
		this.filteredOptions = this.initFilteredOptions();
		this.valueChanges = this.childControl.valueChanges.subscribe(this.valueChangesReplaySubject);
	}

	ngOnInit() {
		this.onSelectSub = this.initOnSelect();
	}

	initFilteredOptions(): Observable<any[]> {
		return combineLatest([this.valueChangesReplaySubject.pipe(startWith('')), this._options$]).pipe(
			map(([value, options]: [T, T[]]) => {
				const term = typeof value !== 'string' ? this.config.display(value) : value;
				if (options == null) {
					return [];
				}
				if (term == null) {
					return options;
				}
				const lowerTerm = term.toLowerCase();
				const o = options.filter((x) => this.config.display(x).toLowerCase().includes(lowerTerm));
				return o;
			})
		);
	}

	private initOnSelect(): Subscription {
		// The pipe used to have a debounceTime(100)
		// this was the stated reason: so that the blur doesn't happen before the selection
		// however, I could not replicate that, and the debounce caused issues on submitting a form straight from the input
		// it would take the previous value of this input instead because of the debounce
		return this.onSelect
			.pipe(withLatestFrom(this._options$.pipe(filter((x) => x != null))))
			.subscribe(([val, options]) => {
				this.selectOption(options, val);
			});
	}

	public async selectOption(options: any[], value: T): Promise<void> {
		if (value) {
			this.value = value;
			return;
		}
		const selectedSearchValue = this.childControl.value;
		// if the selection was cleared
		if (selectedSearchValue == null || selectedSearchValue.length === 0) {
			this.log.debug('[Option] cleared');
			this.value = null;
			return;
		}

		if (options != null) {
			const lowerOptionSelectionDisplay =
				typeof selectedSearchValue !== 'string'
					? this.config.display(selectedSearchValue).toLowerCase().trim()
					: selectedSearchValue.toLowerCase().trim();
			const foundOption = options.find((x) => this.config.display(x).toLowerCase() === lowerOptionSelectionDisplay);
			// TODO: Handle duplicate string matches. Don't select a value in that case.
			// if a typed string matches an existing asset type
			if (foundOption != null) {
				this.log.debug('[Option] found', { searchString: selectedSearchValue, foundOption });
				this.value = foundOption;
				return;
			}
		}
		if (this.allowUnmatched) {
			this.value = this.childControl.value;
		} else {
			// Remove old value if no match is found
			this.value = null;
		}
	}

	matDisplay = (val: T) => (val ? this.config.display(val) : '');

	ngOnDestroy(): void {
		if (this.onSelectSub) {
			this.onSelectSub.unsubscribe();
		}
		if (this.valueChanges) {
			this.valueChanges.unsubscribe();
		}
	}

	registerOnChange(fn: (value: T) => void) {
		this.onChange = fn;
	}

	writeValue(value: T) {
		if (value === null) {
			this.childControl.reset();
		} else {
			this.value = value;
		}
	}

	registerOnTouched(fn: () => void) {
		this.onTouched = fn;
	}

	indexTracker(index: number) {
		return index;
	}

	// We only track the first error because in most cases only one error should ever show. If that changes, someone can update this code
	getMessagedError(): string {
		if (!this.config.errorMessages) return null;
		const errors = Object.keys({ ...this.parentControl?.errors, ...this.childControl.errors });
		if (!errors.length) return null;
		return this.config.errorMessages[errors[0]];
	}
}
