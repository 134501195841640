import { OnDestroy, Pipe, PipeTransform } from '@angular/core';

import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Term, Terms } from '@app/shared/data';

import { TenantService } from '../services/tenant.service';

export enum TermKey {
	noteworthyUpdate = 'NOTEWORTHY_UPDATE',
	kpi = 'KPI',
	initiative = 'INITIATIVE',
	imperative = 'IMPERATIVE',
	issue = 'ISSUE',
	qualificationPath1 = 'QUAL_PATH_1',
	qualificationPath2 = 'QUAL_PATH_2',
	bookingStep = 'BOOKING_STEP',
	staffingQualTeam = 'STAFFING_QUAL_TEAM',
	staffingOppTeam = 'STAFFING_OPP_TEAM',
}

const defaultTerms: Record<TermKey, Term> = {
	[TermKey.noteworthyUpdate]: {
		singular: 'Noteworthy Update',
		plural: 'Noteworthy Updates',
	},
	[TermKey.kpi]: {
		singular: 'KPI',
		plural: 'KPIs',
	},
	[TermKey.initiative]: {
		singular: 'Initiative',
		plural: 'Initiatives',
	},
	[TermKey.imperative]: {
		singular: 'Imperative',
		plural: 'Imperatives',
	},
	[TermKey.issue]: {
		singular: 'Issue',
		plural: 'Issues',
	},
	/* Note that these terms are only for the bookings model and not elsewhere the same term appears
	 * This is because it seems the bookings model in this case is being repurposed
	 * to show qaulity of leads for the same sales team rather than two different teams. */
	// "Sales" is a single entity and is its own plural
	[TermKey.qualificationPath1]: {
		singular: 'Inside Sales',
		plural: 'Inside Sales',
	},
	[TermKey.qualificationPath2]: {
		singular: 'Sales',
		plural: 'Sales',
	},
	[TermKey.bookingStep]: {
		singular: 'step',
		plural: 'steps',
	},
	[TermKey.staffingQualTeam]: {
		singular: 'Qualification Team',
		plural: 'Qualification Team',
	},
	[TermKey.staffingOppTeam]: {
		singular: 'Opportunity Team',
		plural: 'Opportunity Team',
	},
};

@Pipe({ name: 'term', pure: false })
export class TermPipe implements PipeTransform, OnDestroy {
	private tenantSub: Subscription;
	private terms: Terms;
	private termKey: TermKey;
	private isPlural: boolean;
	private term: string;

	constructor(tenantService: TenantService) {
		this.tenantSub = tenantService
			.getTenant()
			.pipe(
				filter((x) => x != null && x.snapshot != null),
				map((x) => x.snapshot.terms)
			)
			.subscribe((terms) => {
				this.terms = terms;
				this.term = this.translate(this.termKey, this.isPlural);
			});
	}

	public transform(key: TermKey, isPlural: boolean = false): string {
		this.termKey = key;
		this.isPlural = isPlural;
		this.term = this.translate(this.termKey, isPlural);
		return this.term;
	}

	translate(key: TermKey, isPlural: boolean): string {
		const term = this.terms && this.terms[key] ? this.terms[key] : defaultTerms[key];
		if (!term) return '';
		return isPlural ? term.plural : term.singular;
	}

	ngOnDestroy(): void {
		if (this.tenantSub) {
			this.tenantSub.unsubscribe();
		}
	}
}
