import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TermKey, TermPipe } from '@app/shared/shared';

import { PersistenceService } from '../../data/dao';
import { Process, StreamData } from '../../data/models';
import { FsCollection, Path } from '../../data/utils';

@Injectable()
export class ProcessRepository {
	constructor(
		private afs: AngularFirestore,
		private persistenceService: PersistenceService,
		private termPipe: TermPipe
	) {}

	async createProcess(tenantId: string, strategyId: string, name?: string): Promise<DocumentReference> {
		const processRef = await this.persistenceService.add(Path.processes(tenantId, strategyId), { name } as Process);
		await Promise.allSettled([
			processRef
				.collection(FsCollection.QualificationPaths)
				.add({ name: this.termPipe.transform(TermKey.qualificationPath1), order: 1 }),
			processRef
				.collection(FsCollection.QualificationPaths)
				.add({ name: this.termPipe.transform(TermKey.qualificationPath2), order: 2 }),
		]);
		return processRef;
	}

	public async getAssociatedStreams(processPath: string): Promise<firebase.firestore.DocumentSnapshot<StreamData>[]> {
		const { tenantId, strategyId } = Path.getIds(processPath);
		const processDoc = this.afs.doc(processPath);
		return (
			await firstValueFrom(
				this.afs
					.collection<StreamData>(Path.streamDataCollection(tenantId, strategyId), (x) =>
						x.where('process', '==', processDoc.ref)
					)
					.get()
			)
		).docs;
	}

	public hasAssociatedBookingsModels(processPath: string): Observable<boolean> {
		const { tenantId, strategyId } = Path.getIds(processPath);
		const processDoc = this.afs.doc(processPath);

		return this.afs
			.collection<StreamData>(Path.streamDataCollection(tenantId, strategyId), (x) =>
				x.where('process', '==', processDoc.ref)
			)
			.get()
			.pipe(map((query) => query.docs.some((doc) => doc.data().bookingsModel)));
	}
}
