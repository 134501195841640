import { Observable } from 'rxjs';

import { WeeklyUpdate } from '../models';
import { BaseDAO } from './base.dao';
import { UserDAO } from './user.dao';

export class WeeklyUpdateDAO extends BaseDAO<WeeklyUpdate> {
	public get user(): Observable<UserDAO> {
		return this.getSubDocumentDao((x) => x.user, UserDAO);
	}
}
