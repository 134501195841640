import { DocumentReference } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';

import { ActionItemStatus, IssueStatus, Priority, TrackingStatus } from '../enums';
import { Identity } from './identity';

export interface NoteworthyUpdate {
	user: DocumentReference;
	content: string;
}

export interface ActionItemUpdate {
	actionItem: DocumentReference;
	status: ActionItemStatus;
}

export interface ImperativeUpdate {
	imperative: DocumentReference;
	notes: string;
	status: TrackingStatus;
}

export interface IssueUpdate {
	issue: DocumentReference;
	resolution: IssueStatus;
	priority: Priority;
}

export interface UpdateMap<T> {
	[iid: string]: T;
}

export interface Meeting extends Identity {
	createdOn: firebase.firestore.Timestamp;
	isCurrent: boolean;
	completedOn: firebase.firestore.Timestamp;
	noteworthyUpdates: UpdateMap<NoteworthyUpdate>;
	actionItemUpdates: UpdateMap<ActionItemUpdate>;
	imperativeUpdates: UpdateMap<ImperativeUpdate>;
	issueUpdates: UpdateMap<IssueUpdate>;
	minutes: string;
}
