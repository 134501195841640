import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';

import { LogService, LogZone } from '@app/shared/core';

export enum ApiState {
	Processing,
	Success,
	Failure,
}

@Injectable()
export class PersistenceService {
	constructor(
		private afs: AngularFirestore,
		private logService: LogService
	) {
		this.logService = logService.for(LogZone.PERSIST);
	}

	randomId(): string {
		return this.afs.createId();
	}

	add<T extends object>(path: string, item: T): Promise<DocumentReference> {
		this.logService.debug('Add', { path, item });
		return this.afs.collection<T>(path).add(item);
	}

	set<T extends object>(path: string, item: T): Promise<void> {
		this.logService.debug('Set', { path, item });
		return this.afs.doc<T>(path).set(item);
	}

	patch<T extends object>(path: string, item: Partial<T>): Promise<void> {
		this.logService.debug('Update', { path, item });
		return this.afs.doc<T>(path).update(item);
	}

	remove(path: string): Promise<void> {
		return this.afs.doc(path).delete();
	}
}
