import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { addWeeks, endOfYear, getWeek, startOfToday, startOfWeek, startOfYear } from 'date-fns';

import { Week } from '@app/shared/data/models/week';

export const DATE_CONFIG = { weekStartsOn: 1, firstWeekContainsDate: 7 } as any; // Shut up typescript

@Injectable()
export class WeekService {
	private _weeks: Week[];
	get currentWeek(): Week {
		const today = startOfToday();
		return this.getWeekFromDate(today);
	}

	constructor(datePipe: DatePipe) {
		this._weeks = this.initWeeks(datePipe);
	}

	public getWeeks(stopDate?: Date): Week[] {
		if (!stopDate) {
			return [...this._weeks];
		}
		const weeks = [];
		for (const week of this._weeks) {
			if (stopDate >= week.value.fromDate) {
				weeks.push(week);
			} else {
				return weeks; // Stop loop after current date is passed
			}
		}
		return weeks;
	}

	public getWeekFromDate(date: Date): Week {
		return this._weeks.find((week) => date >= week.value.fromDate && date < week.value.toDate);
	}

	private initWeeks(datePipe: DatePipe) {
		const today = startOfToday();
		const decThirtyFirst = endOfYear(today);
		const weeks: Week[] = [];

		const janFirst = startOfYear(decThirtyFirst);
		let firstMonday = startOfWeek(janFirst, DATE_CONFIG);
		if (firstMonday < janFirst) {
			// Advances week forward if Monday occurred in previous year
			firstMonday = addWeeks(firstMonday, 1);
		}

		const finalWeek = getWeek(decThirtyFirst, DATE_CONFIG);
		for (let i = 0; i < finalWeek; i++) {
			const weekDate = addWeeks(firstMonday, i);
			const weekNumber = getWeek(weekDate, DATE_CONFIG);

			const dateString = datePipe.transform(weekDate, 'MM/dd');
			weeks.push({
				view: `Week ${weekNumber}, starting on ${dateString}`,
				value: {
					month: weekDate.getMonth() + 1,
					dayOfMonth: weekDate.getDate(),
					fromDate: weekDate,
					toDate: addWeeks(weekDate, 1),
				},
			});
		}

		return weeks;
	}
}
