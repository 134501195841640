import { Pipe, PipeTransform } from '@angular/core';

import { Months } from '../constants';

@Pipe({ name: 'numberToMonth' })
export class NumberToMonthPipe implements PipeTransform {
	transform(value: number): string {
		const month = Months.find((x) => x.value === value);
		return month ? month.longName : '';
	}
}
