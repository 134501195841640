import { AbstractControl, ValidatorFn } from '@angular/forms';

import { unmaskNumber } from '../utils';
import { isEmptyInputValue } from './shared';

export function unmaskRequired(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const err = { required: true };
		let { value } = control;
		if (isEmptyInputValue(value)) {
			return err;
		}
		if (typeof value === 'string') {
			value = unmaskNumber(value);
		}
		const floatValue = parseFloat(value);
		return Number.isNaN(floatValue) ? err : null;
	};
}
