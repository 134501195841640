import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'roundUp',
})
export class RoundUpPipe implements PipeTransform {
	constructor(private numberPipe: DecimalPipe) {}

	transform(value: any, digitsInfo?: string, locale?: string): any {
		if (value == null) {
			return null;
		}
		let number = parseInt(value, 10);
		if (Number.isNaN(number)) {
			return null;
		}

		let maxFractionDigits = 3; // this is the default for the decimal pipe
		if (digitsInfo != null) {
			const matches = digitsInfo.match(/^(\d*)\.(\d*)-(\d*)$/);
			if (matches) {
				maxFractionDigits = parseInt(matches[3], 10);
			}
		}
		const shift = 10 ** maxFractionDigits;
		number = Math.ceil(number * shift) / shift;

		const result = this.numberPipe.transform(number, digitsInfo, locale);
		return result;
	}
}
