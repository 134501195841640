import { Observable } from 'rxjs';

import { Process } from '../models';
import { FsCollection } from '../utils';
import { BaseDAO } from './base.dao';
import { OpportunitiesDAO } from './opportunities.dao';
import { QualificationPathDAO } from './qualification-path.dao';
import { QualificationStageDAO } from './qualification-stages.dao';

export class ProcessDAO extends BaseDAO<Process> {
	private _qualificationStages: Observable<QualificationStageDAO[]>;
	private _opportunities: Observable<OpportunitiesDAO[]>;
	private _qualificationPaths: Observable<QualificationPathDAO[]>;

	public get qualificationStages(): Observable<QualificationStageDAO[]> {
		if (this._qualificationStages == null) {
			const path = `${this.path}/${FsCollection.QualificationStages}`;
			this._qualificationStages = this.initCollection(QualificationStageDAO, path);
		}
		return this._qualificationStages;
	}

	public get opportunities(): Observable<OpportunitiesDAO[]> {
		if (this._opportunities == null) {
			const path = `${this.path}/${FsCollection.Opportunities}`;
			this._opportunities = this.initCollection(OpportunitiesDAO, path);
		}
		return this._opportunities;
	}

	public get qualificationPaths(): Observable<QualificationPathDAO[]> {
		if (this._qualificationPaths == null) {
			const path = `${this.path}/${FsCollection.QualificationPaths}`;
			this._qualificationPaths = this.initCollection(QualificationPathDAO, path);
		}
		return this._qualificationPaths;
	}

	public get isEmpty(): boolean {
		if (!this.snapshot) {
			return true;
		}
		const { name = '', qualificationHandoff = [], leadGenerationHandoff = [] } = this.snapshot;
		return !name && qualificationHandoff.length === 0 && leadGenerationHandoff.length === 0;
	}
}
