import { StorageType } from '../constants/webstorage.constants';

export class WebStorage {
	public static getItem(key: string, storageType: StorageType = StorageType.localStorage) {
		const value = storageType === StorageType.sessionStorage ? sessionStorage.getItem(key) : localStorage.getItem(key);
		return value ? JSON.parse(value) : value;
	}

	public static setItem(key: string, value: any, storageType: StorageType = StorageType.localStorage): void {
		if (value == null) return;
		if (storageType === StorageType.sessionStorage) {
			sessionStorage.setItem(key, JSON.stringify(value));
		} else {
			localStorage.setItem(key, JSON.stringify(value));
		}
	}

	public static removeItem(key: string, storageType: StorageType = StorageType.localStorage): void {
		if (storageType === StorageType.sessionStorage) {
			localStorage.removeItem(key);
		} else {
			localStorage.removeItem(key);
		}
	}

	public static clear(storageType: StorageType = StorageType.localStorage): void {
		if (storageType === StorageType.sessionStorage) {
			localStorage.clear();
		} else {
			localStorage.clear();
		}
	}
}
