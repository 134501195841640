import { DocumentReference } from '@angular/fire/compat/firestore';

import { TrackingStatus } from '../enums';
import { CampaignResponseType } from '../enums/campaign-response.enum';
import { RevenueStream } from './revenue-stream';
import { SegmentValue } from './segment-value';

export const ACTION_DATE_FORMAT = 'M/d/y';

export interface Campaign {
	name: string;
	providerId?: string;
	description?: string;
	budget?: number;
	parent: DocumentReference<Campaign> | null;
	order: number;
	attributes?: [DocumentReference<SegmentValue | RevenueStream>];
	responses?: Record<string, CampaignResponse>;
	attributions?: Record<string, CampaignAttribution>;
}

export interface CampaignResponse {
	type: CampaignResponseType;
	typeId?: string;
	typeName?: string;
	actions?: { [key: string]: CampaignAction };
}

export interface CampaignAttribution {
	responseType: string;
	responseInteraction: string;
	attribution?: string;
	expirationDays?: number;
}

export interface CampaignAction {
	date: string; // this is the startDate for a range, but we don't want to migrate data
	endDate?: string;
	status: TrackingStatus;
	assignee: string;
	notes: string;
}
