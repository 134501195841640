import { inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivateFn, Router } from '@angular/router';

import { map, take } from 'rxjs/operators';

export const RETURN_URL_KEY = 'returnUrl';

// eslint-disable-next-line func-style
export const authGuard: CanActivateFn = () => {
	const afAuth = inject(AngularFireAuth);
	const router = inject(Router);

	return afAuth.user.pipe(
		take(1),
		map((user) => {
			if (user) {
				return true;
			}
			return router.parseUrl(`/login?${RETURN_URL_KEY}=${window.location.pathname}`);
		})
	);
};
