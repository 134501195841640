import { NgModule } from '@angular/core';

import { DAOCollectionFactory, DaoFactory, PersistenceService } from './dao';
import {
	ActionItemRepository, CollaborationMeetingActionItemRepository, ImperativeRepository, InitiativeRepository,
	IssueRepository, MeetingRepository, ProgramRepository, QuarterlyPlanRepository, RecursiveDeleteService,
	StrategyTrackingActionItemRepository, StreamDataRepository, UserRepository,
} from './repositories';

@NgModule({
	imports: [],
	declarations: [],
	providers: [
		ActionItemRepository,
		UserRepository,
		StreamDataRepository,
		DaoFactory,
		DAOCollectionFactory,
		InitiativeRepository,
		ImperativeRepository,
		MeetingRepository,
		QuarterlyPlanRepository,
		RecursiveDeleteService,
		PersistenceService,
		ProgramRepository,
		IssueRepository,
		CollaborationMeetingActionItemRepository,
		StrategyTrackingActionItemRepository,
	],
})
export class DataModule {}
