import { Injectable, OnDestroy } from '@angular/core';

import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class AccordionExpansionService implements OnDestroy {
	private _sub: Subscription;
	private _expandedId = new ReplaySubject<string>(1);
	private _debouncedIdUpdater = new Subject<string>();

	constructor() {
		// accordion emits the first close as null and the open as the id so we wait
		this._sub = this._debouncedIdUpdater.pipe(debounceTime(500)).subscribe(this._expandedId);
	}

	public setExpandedId(id: string): void {
		this._debouncedIdUpdater.next(id);
	}

	public getExpandedId(): Observable<string> {
		return this._expandedId.asObservable();
	}

	ngOnDestroy(): void {
		this._sub.unsubscribe();
	}
}
