import { Observable } from 'rxjs';

import { Issue } from '../models/issue';
import { BaseDAO } from './base.dao';
import { UserDAO } from './user.dao';

export class IssueDAO extends BaseDAO<Issue> {
	public get createdBy(): Observable<UserDAO> {
		return this.getSubDocumentDao((x) => x.createdBy, UserDAO);
	}
}
