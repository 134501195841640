import { Component, Input } from '@angular/core';

@Component({
	selector: 'ayeq-help-tooltip',
	templateUrl: './help-tooltip.component.html',
	styleUrl: './help-tooltip.component.scss',
})
export class HelpTooltipComponent {
	@Input() color: 'blue' | 'green' | 'grey';
}
